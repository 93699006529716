import React from 'react';
import { Section, Container } from '../index';

const Grid2 = ({ textAreaLeft, textAreaRight, styles }) => {
     return (
         <Section styles={`theme__section p-rel grid2 ${ styles } `}>
               <Container styles='grid2__left cont-50 ps-2'>
                    { textAreaLeft }
               </Container>
               <Container styles='grid2__right cont-50 ps-2'>
                    { textAreaRight }
               </Container>
         </Section>
     )
};

export default Grid2;

// Container handles 2 item grid
