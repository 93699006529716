import React from 'react';
import { useLocation } from 'react-router-dom';
import { AiOutlineBehance, AiOutlineFacebook, AiOutlineLinkedin, AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import img from '../../images/img-999.png';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/authSlice';

const Footer = () => {
     const location = useLocation();
     const { isAuthenticated } = useSelector(state => state.auth);
     const dispatch = useDispatch();
     const date = new Date();
     const getInTouch = [
          { link: 'mailto:info@miartmedia.com', text: 'info@miartmedia.com' },
          { link: 'tel:+14039909070', text: '(403) 990-9070' },
          { link: '/login', text: 'Login' },
          { link: '/register', text: 'Register' },
          // { link: '/book_photography', text: 'Book Photography' }
     ];
     const explore = [
          { link: '/photography-portfolio', text: 'Portfolio'},
          { link: '/photography-blog', text: 'Blog'},
          { link: '/real-estate-and-rental-photography', text: 'Real Estate and Rental'},
          { link: '/interior-and-architectural-photography', text: 'Interior and Architectural Photography'},
          { link: '/commercial-and-hospitality-photography', text: 'Commercial and Hospitality Photography'},
          { link: '/food-photography', text: 'Food Photography' },
          { link: '/', text: 'Home' },
     ];
     const social = [
          {link: 'https://www.facebook.com/MiArtMedia/', icon: <AiOutlineFacebook/> }, 
          {link: 'https://www.behance.net/mikhailzapolsk/', icon: <AiOutlineBehance/> },
          {link: 'https://www.linkedin.com/company/miartmedia-creative-studio', icon: <AiOutlineLinkedin/> },
          {link: 'https://twitter.com/MiArtMedia', icon: <AiOutlineTwitter/> },
          {link: 'https://www.instagram.com/miartmedia/', icon: <AiFillInstagram/> }
     ];
     const area = ['Calgary', 'Airdrie', 'Okotoks', 'Cochrane', 'Canmore', 'and more'];
     const [ path ] = ['/dashboard', '/dashboard/blog', '/dashboard/portfolio', '/dashboard/notifications', '/dashboard/billing', '/dashboard/profile',
     '/dashboard/rank'].filter(item => location.pathname === item);

     return (
               <React.Fragment>
                    { path === location.pathname ? false : 
                         <footer className='footer pt-5'>
                              <div className='theme__section footer__top'>
                                   <div className='cont-25 t-center footer__top--box'>
                                        <p className='subtitle__wh mb-2'>Get in touch</p>
                                        { isAuthenticated
                                             ? <React.Fragment>
                                                  <a href='mailto:miartmedialtd@gmail.com' className='text footer__link'>miartmedialtd@gmail.com</a> 
                                                  <a href='tel:+14039909070' className='text footer__link'>(403) 990-9070</a>
                                                  <p className='text footer__link' onClick={() => dispatch(logout())}>Logout</p>
                                                  <a href='/dashboard' className='text footer__link'>Dashboard</a>
                                             </React.Fragment>
                                             :    getInTouch.map(i => 
                                                       <a key={ i.link } href={ i.link } className='text footer__link'>{ i.text }</a> 
                                                  )
                                        }
                                   </div>
                                   <div className='cont-25 t-center footer__top--box'>
                                        <p className='subtitle__wh mb-2'>Explore</p>
                                        { explore.map(i => <a key={ i.link } href={ i.link } className='text footer__link' >{ i.text }</a>) }
                                   </div>
                                   <div className='cont-25 t-center footer__top--box'>
                                        <p className='subtitle__wh mb-2'>Area We Cover</p>
                                        { area.map(i => 
                                             <p key={ i } className='text footer__text'>{ i }</p> 
                                        )}
                                   </div>
                                   <div className='cont-25 t-center footer__top--box'>
                                        <p className='subtitle__wh mb-2'>Let's Get Social</p>
                                        <div className='flex-row footer__top--social'>
                                             { social.map(i => 
                                                  <a key={ i.link } href={ i.link } target='_blank' rel="noreferrer" className='text footer__top--icons'>{ i.icon }</a>
                                             )}
                                        </div>
                                   `    <a href='https://www.thebestcalgary.com/best-calgary-photographers/#36_MiArtMedia'>
                                             <img src={ img } alt='Best Photographer in Calgary' className='footer__top--image mt-2'/>
                                        </a>
                                   </div>
                              </div>
                              <div className='theme__section footer__middle'>
                                   <div>
                                        <p className='subtitle__wh mb-2'>Tags</p>
                                        <p className='text t-justify footer__tags'>
                                             Calgary real estate photography, real estate photography Calgary, 
                                             Calgary real estate photo, real estate photos, property photos, 
                                             property photography, Calgary interior photography, 
                                             interior photography Calgary, interior photos, interior design photography, 
                                             interior photographer, Calgary interior images, architectural photography, 
                                             architectural photos, architectural photographer, architectural images, 
                                             hospitality photography, restaurant photography, food photography, 
                                             portraits photography, architecture photography, real estate photography services, 
                                             re photography, architect photography, Calgary professional photography, 
                                             design photography, commercial photography Calgary, commmercial, hospitality</p>
                                   </div>
                              </div>
                              <div className='theme__section mt-5 footer__bottom'>
                                   <p className='text footer__copywrite'>Copyright © MiArtMedia { date.getFullYear() }</p>
                              </div>
                         </footer>
                    }
               </React.Fragment>
     )
};

export default Footer;