import React from 'react';
import { Container, Section, SubTitle, Text } from '../index';

const Features = () => {
     return (
          <Section styles='theme__section mt-10 mb-10 flex-row flex-jus-ctr feature'>
               <Container styles='cont-33 pf-2 flex-col flex-al-ctr feature__content'>
                    <SubTitle title='SUPERIOR QUALITY' className='mr--tmd'/>
                    <hr className='short-line' />
                    <Text 
                         text='We provide our clients unique visual materials that have the power to elevate their listings and enhance their marketing efforts. 
                              Every photograph is carefully staged. Our editing process enhances each photo to look its best.'
                         styles='text t-justify'
                    />
               </Container>
               <Container styles='cont-33 pf-2 flex-col flex-al-ctr feature__content'>
                    <SubTitle title='PERSONAL APPROACH' />
                    <hr className='short-line' />
                    <Text 
                         text='We build a visual connection between a consumer and a property with each photograph we take.
                              We look for the best viewpoints and aspects of a residential or commercial property that matches expectations.
                              Our images are intended to illustrate the story that buyers create in their minds when they are looking for a home.'
                         styles='text t-justify'
                    />
               </Container>
               <Container styles='cont-33 pf-2 flex-col flex-al-ctr feature__content'>
                    <SubTitle title='SATISFACTION GUARANTEE' />
                    <hr className='short-line' />
                    <Text 
                         text='12-24 hours turnaround. If you are unhappy with the result of our product we will do everything we can to improve it or credit you for that job. It’s as simple as that!'
                         styles='text t-justify'
                    />
               </Container>
          </Section>
     )
};

export default Features;
