import React, { useState, useEffect, useRef } from 'react';
import { BackgroundSlider, BackgroundOverlay, Container } from '../index';
import img1 from '../../images/img-001.jpg';
import img2 from '../../images/Miartmedia - 0548.jpg';
import img3 from '../../images/miartmedia-0711.jpg';
import img4 from '../../images/miartmedia-0732.jpg';
import logo_wh from '../../images/img-logo-wh.png';
import { gsap } from 'gsap';


const Carousel = () => {
     const [ currentImage, setCurrentImage ] = useState(0);
     const [ play, setPlay ] = useState(true);

     const fadeInBackground = useRef();
     const rotateLogo = useRef();
     const fadeInTitle = useRef();

     const slides = [
          {image : img1, text : 'Real Estate and Rental Photography'},
          {image : img2, text : 'Interior and Architectural Photography'},
          {image : img3, text : 'Commercial and Hospitality Photography'},
          {image : img4, text : 'Food Photography'},
     ];

     useEffect(() => {
          if (play === true) {
               const next = (currentImage + 1) % slides.length;
               const id = setInterval(() => setCurrentImage(next), 7000);
               gsap.fromTo(fadeInBackground.current, { opacity: 0, scale: 1.5 }, {opacity: 1, scale: 1, duration: 3, ease: 'slow' });
               // gsap.fromTo(rotateLogo.current, { rotate: 0, scale: 0 }, { rotate: '+=360', scale: 1, duration: 3, ease: 'bounce' })
               gsap.fromTo(fadeInTitle.current, { scale: 0 }, { scale: 1, duration: 4, ease: 'expo' })
               return () => clearInterval(id);

          } if ( play === false ) {
               setCurrentImage(currentImage);
          }
     }, [currentImage, slides.length, play, fadeInBackground, rotateLogo]);
     
     const handleSlide = (e) => {
          setPlay(false);
          setInterval(() => setPlay(true), 5000);
     };

     return (
          <Container styles='p-rel carousel' func={ handleSlide }>
               <BackgroundSlider styles={`carousel__background`} animate={ fadeInBackground } img={ slides[currentImage].image}/>
               <BackgroundOverlay styles='carousel__overlay' />
               <Container styles='p-ctr flex-col flex-jus-ctr flex-al-st theme__section carousel__content'>
                    <img ref={ rotateLogo } className='carousel__logo' src={ logo_wh } alt="miartmedia creative studio"/>
                    <h1 ref={ fadeInTitle } className='title carousel__header'>{ slides[currentImage].text }</h1>
               </Container>
          </Container>
     )
};

export default Carousel;
