import React from "react";
import { Title, SubTitle, Price, FeatureList, Container } from "../index";

const PriceCard = ({ title, subtitle, price, children, func, color }) => {
	return (
		<Container styles="priceCard flex-al-ctr flex-jus-ctr">
			<Container styles="pt-2 pb-1 priceCard__base">
				<Title styles="priceCard__title" title={title} />
				<SubTitle styles="priceCard__subtitle" title={subtitle} />
				<Price price={price} />
			</Container>
			<FeatureList children={children} />
		</Container>
	);
};

export default PriceCard;
