import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Text } from '../index';

const ErrorCard = () => {
     return (
          <Container styles='flex-col flex-al-ctr flex-jus-ctr errorCard'>
               <Text 
                    styles='errorCard__text' 
                    text='Sorry. Somthing went wrong on our side'
               />
               <Link to='/' className='basic-btn' >Go to main page</Link>
          </Container>
     )
};

export default ErrorCard;
