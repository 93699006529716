import React, { useState } from 'react';
import { Section, Container, SingleImage } from '../index';
import { AiOutlineClose, AiOutlineLink, AiOutlineArrowsAlt } from "react-icons/ai";
import { Link } from 'react-router-dom';
import img from '../../images/Miartmedia - 0362.jpg';

const Accordeon = () => {
     const [ content, setContent ] = useState({ text: '', to: '' });
     const [ flip, setFlip ] = useState({ list: true, text: false });

     const handleDisplay = (e) => {
          switch(e.target.id) {
               case '1' : 
                    setContent({
                         text: 'We take our time to show your property in the very best light', 
                         to: '/real_estate_and_rental_photography'
                    });
               break;
               case '2' : 
                    setContent({
                         text: 'We look closely to identify aspects of an interior that make living or commercial spaces visually captivating',
                         to: '/interior_and_architectural_photography'
                    });
               break;
               case '3' : 
                    setContent({
                         text: 'We strive to create the quality images you expect. The goal is to attain what you would like your customers to imagine hotel and resort, restaurant or shop to be',
                         to: '/commercial_and_hospitality_photography'
                    });
               break;
               case '4' : 
                    setContent({
                         text: `You are in control of what you show and what image you put out to the world.
                         Professional food photos can help you build a brand that stands out and shapes your 
                         restaurant identity.`,
                         to: '/food'
                    });
               break;
               default : setContent({ text: '', to: '' });
          }

          setFlip({list: !flip.list, text: !flip.text});
     };

     return (
          <Section styles='theme__section mt-5 mb-5 flex-row accordeon'>
               <Container styles='cont-50 p-rel accordeon__image'>
                    <SingleImage styles='accordeon__image--img' img={ img }/>
               </Container>
               <Container styles='cont-50 flex flex-jus-st flex-al-ctr accordeon__content'>
                    <div className={ flip.list ? 'accordeon__show' : 'accordeon__hide' }>
                         <ul onClick={ handleDisplay }>
                              <li id='1'>
                                   Real Estate and Rental <AiOutlineArrowsAlt className='accordeon__content--icon'/>
                              </li>
                              <li id='2'>
                                   Interior and Architectural <AiOutlineArrowsAlt className='accordeon__content--icon'/>
                              </li>
                              <li id='3'>
                                   Commercial <AiOutlineArrowsAlt className='accordeon__content--icon'/>
                              </li>
                              <li id='4'>
                                   Food <AiOutlineArrowsAlt className='accordeon__content--icon'/>
                              </li>
                         </ul>
                    </div>
                    <div className={`accordeon__content--text ${ flip.text ? 'accordeon__show' : 'accordeon__hide' }`}>
                         <p>{ content.text }</p>
                         <AiOutlineClose className='accordeon__content--text-icon' onClick={ handleDisplay }/>
                         <Link to={ content.to } className='accordeon__content--text-link'><AiOutlineLink/></Link>
                    </div>
               </Container>
          </Section>
     )
};

export default Accordeon;
