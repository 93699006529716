import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import real from "../../images/img-001.jpg";
import interior from "../../images/img-002.jpg";
import commercial from "../../images/miartmedia-0711.jpg";
import food from "../../images/miartmedia-0732.jpg";

const NavListItem = ({ text, link, func }) => {
	return (
		<React.Fragment>
			<Link className="nav__list-item" onMouseEnter={func} to={link}>
				<p className="nav__list-link">{text}</p>
			</Link>
		</React.Fragment>
	);
};

const NavListButton = ({ text, func }) => {
	return (
		<React.Fragment>
			<li className="nav__list-item" onClick={func}>
				<p className="nav__list-link">{text}</p>
			</li>
		</React.Fragment>
	);
};

const Nav = ({ nav }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const [image, setImage] = useState(real);
	const navBackground = {
		backgroundImage: `url('${image}')`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		transition: "all .4s linear",
	};

	const handleImage = (e) => {
		const section = e.target.firstChild.innerHTML
			.split(" ")[0]
			.toLowerCase();

		switch (section) {
			case "real":
				setImage(real);
				break;
			case "interior":
				setImage(interior);
				break;
			case "commercial":
				setImage(commercial);
				break;
			case "food":
				setImage(food);
				break;
			default:
				setImage(real);
		}
	};

	return (
		<div className="nav navIn" style={navBackground} onClick={nav}>
			<div className="nav__list navListIn">
				<ul>
					<NavListItem
						text="Real Estate and Rental"
						link="/real-estate-and-rental-photography"
						func={handleImage}
					/>
					<NavListItem
						text="Interior and Architectural Photography"
						link="/interior-and-architectural-photography"
						func={handleImage}
					/>
					<NavListItem
						text="Commercial and Hospitality Photography"
						link="/commercial-and-hospitality-photography"
						func={handleImage}
					/>
					<NavListItem
						text="Food Photography"
						link="/food-photography"
						func={handleImage}
					/>
				</ul>
				<ul>
					<NavListItem
						text="Portfolio"
						link="/photography-portfolio"
						func={handleImage}
					/>
					<NavListItem
						text="Blog"
						link="/photography-blog"
						func={handleImage}
					/>
					<NavListItem
						text="Contact"
						link="/contact"
						func={handleImage}
					/>
				</ul>
				<ul>
					{user ? (
						<React.Fragment>
							<NavListButton
								text="Logout"
								func={() => dispatch(logout())}
							/>
							<NavListItem
								text="Dashboard"
								link="/dashboard"
								func={handleImage}
							/>
						</React.Fragment>
					) : (
						<React.Fragment>
							<NavListItem
								text="Login"
								link="/login"
								func={handleImage}
							/>
							<NavListItem
								text="Register"
								link="/register"
								func={handleImage}
							/>
						</React.Fragment>
					)}
					{/* <NavListItem text='Book Photography' link='/book_photography' func={ handleImage } /> */}
					<NavListItem text="Home Page" link="/" />
				</ul>
			</div>
		</div>
	);
};

export default Nav;
