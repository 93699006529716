import React from 'react';
import { BackgroundImage, Text } from '../index';
import { Link } from 'react-router-dom';

const PortfolioCard = ({ text, img, to }) => {
     return (
          <Link to={ to } className='portfolioCard p-rel m-1'>
               <BackgroundImage styles='portfolioCard__background p-abs' img={ img }/>
               <Text 
                    styles='p-abs portfolioCard__text p-ctr'
                    text={ text }
               />
          </Link>
     )
};

export default PortfolioCard;
