export const clearInvoiceForm = (
	setInvoiceData,
	setCustomerSaved,
	setAddFormStatus
) => {
	setInvoiceData({
		userId: "",
		company: "",
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		address1: "",
		city: "",
		country: "",
		state: "",
		postal: "",
		invoiceNumber: "",
		date: "",
		items: [
			{
				id: 1,
				service: "",
				description: "",
				quantity: 1,
				price: 0,
				amount: 0,
			},
		],
		discount: 0,
		subTotal: 0,
		tax: 0,
		total: 0,
		notes: "",
	});

	setCustomerSaved(false);
	setAddFormStatus(false);
};
