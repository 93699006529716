import React, { useContext } from "react";
import { RiPlayListAddLine } from "react-icons/ri";
import { InvoiceContext } from "../../../context/context";

const InvoiceForm = ({
	buttons,
	submit,
	customerForm,
	AddInvoiceInfo,
	items,
	addItem,
	customerInfo,
	total,
	notes,
}) => {
	const { customerSaved } = useContext(InvoiceContext);

	return (
		<form className="formGrid" type="post" onSubmit={submit}>
			<div className="flex flex-jus-end">{buttons}</div>
			<div className="invoiceForm">
				<div className="invoiceForm__header">
					{customerSaved ? customerInfo : customerForm}
					<div className="formInputsGrid">{AddInvoiceInfo}</div>
				</div>
				<div className="invoiceForm__body">
					<ul className="invoiceForm__body--itemsDescription">
						<li>Services</li>
						<li>Quantity</li>
						<li>Price</li>
						<li>Amount</li>
						<li>Action</li>
					</ul>
					<div className="invoiceForm__body--itemsList">
						{items}
					</div>
					<RiPlayListAddLine
						className="dashboard__icon jus-self-ctr al-self-ctr"
						onClick={addItem}
					/>
					<div className="invoiceForm__body--total">{total}</div>
				</div>
				<div className="invoiceForm__footer">{notes}</div>
			</div>
			<div className="basicForm__btn">
				<button className="fullWidth-btn portfolioForm__btn">
					Save Changes
				</button>
			</div>
		</form>
	);
};

export default InvoiceForm;
