import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
	Home,
	RealEstate,
	Interior,
	Commercial,
	Food,
	Portfolio,
	Project,
	Blog,
	Post,
	Contact,
	Login,
	Register,
	Appointment,
	ForgotPassword,
	ResetPassword,
	ErrorPage,
	Dashboard,
	PortfolioBoard,
	BlogBoard,
	BillingBoard,
	NotificationsBoard,
	ProfileBoard,
	RankBoard,
	MainBoard,
} from "../pages";

import GATracking from "../helpers/gaTracking";

const PrivateRoute = ({ user, children }) => {
	return user ? children : <Navigate to="/login" />;
};

const Router = () => {
	GATracking();
	const user = useSelector((state) => state.auth.user);

	return (
		<Routes>
			<Route exact path="/" element={<Home />} />
			<Route
				path="/real-estate-and-rental-photography"
				element={<RealEstate />}
			/>
			<Route
				path="/interior-and-architectural-photography"
				element={<Interior />}
			/>
			<Route
				path="/commercial-and-hospitality-photography"
				element={<Commercial />}
			/>
			<Route path="/food-photography" element={<Food />} />
			<Route
				exact
				path="/photography-portfolio"
				element={<Portfolio />}
			/>
			<Route path="/photography-portfolio/:id" element={<Project />} />
			<Route exact path="/photography-blog" element={<Blog />} />
			<Route path="/photography-blog/:id" element={<Post />} />
			<Route path="/contact" element={<Contact />} />
			<Route path="/login" element={<Login />} />
			<Route path="/register" element={<Register />} />
			<Route path="/book-photography" element={<Appointment />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password/:id" element={<ResetPassword />} />
			<Route
				path="/dashboard"
				element={
					<PrivateRoute user={user}>
						<Dashboard />
					</PrivateRoute>
				}
			>
				<Route index element={<MainBoard />} />
				<Route path="blog" element={<BlogBoard />} />
				<Route path="portfolio" element={<PortfolioBoard />} />
				<Route path="billing" element={<BillingBoard />} />
				<Route
					path="notifications"
					element={<NotificationsBoard />}
				/>
				<Route path="profile" element={<ProfileBoard />} />
				<Route path="rank" element={<RankBoard />} />
			</Route>
			<Route path="*" element={<ErrorPage />} />
		</Routes>
	);
};

export default Router;
