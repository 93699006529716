import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const GATracking = () => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.initialize("G-54BR4D4HGG");
		ReactGA.send({ hitType: "pageview", page: location.pathname });
	}, [location]);
};

export default GATracking;
