import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
	DblSectionMedia,
	Grid2,
	DblColumn,
	SingleImage,
	BackgroundOverlay,
	InfoCard,
	Parallax,
	ContactSection,
} from "../components/index";
import { CommercialOption } from "./index";
import img from "../images/miartmedia-0711.jpg";

function Commercial() {
	// const color = 'rgb(203, 209, 184)';
	const color = "rgb(200, 133, 70)";

	return (
		<main>
			<Helmet>
				<title>
					Commercial and Hospitality Photography | MiArtMedia
					Creative Studio
				</title>
				<meta
					name="description"
					content="Why Commercial and Hospitality photography is crucial? 
                    Commercial and Hospitality Photography captures the very embodiment of your business. 
                    It points out the impalpable characteristics of the place "
				/>
				<link
					rel="canonical"
					href="https://miartmedia.com/commercial-and-hospitality-photography"
				/>

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://miartmedia.com/commercial-and-hospitality-photography"
				/>
				<meta
					property="og:title"
					content="Commercial and Hospitality Photography | MiArtMedia Creative Studio"
				/>
				<meta
					property="og:description"
					content="Commercial and Hospitality Photography captures the very embodiment of your business. 
                    It points out the impalpable characteristics of the place"
				/>
				<meta property="og:image" content={img} />

				{/* Twitter */}
				<meta
					property="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content="https://miartmedia.com/commercial-and-hospitality-photography"
				/>
				<meta
					property="twitter:title"
					content="Commercial and Hospitality Photography | MiArtMedia Creative Studio"
				/>
				<meta
					property="twitter:description"
					content="Commercial and Hospitality Photography captures the very embodiment of your business. 
                    It points out the impalpable characteristics of the place"
				/>
				<meta property="twitter:image" content={img}></meta>
			</Helmet>
			<DblSectionMedia
				textSide={
					<DblColumn
						title="Commercial and Hospitality Photography"
						subtitle="RESTAURANTS | HOTELS | BUSINESS | STORES"
						linkText="Learn more Home"
						to="/photography-portfolio"
						bgColor={color}
					/>
				}
				imageSide={
					<React.Fragment>
						<SingleImage
							styles="dblSection--image"
							img={img}
						/>
						<BackgroundOverlay styles="dblSection--backgroundOverlay" />
					</React.Fragment>
				}
			/>
			<Grid2
				styles="pt-10 pb-10"
				textAreaLeft={
					<InfoCard
						titleStyles="t-center"
						title="WHY COMMERCIAL AND HOSPITALITY PHOTOGRAPHY IS CRUCIAL?"
						text={
							<React.Fragment>
								Commercial and Hospitality Photography
								captures the very embodiment of your
								hotel and resort, restaurant, business
								or store. It points out the impalpable
								characteristics of the place, it sets
								the mood, and it helps clients to
								visualize and feel the way they will
								spend their time. <br />
								<br /> With the growth of businesses
								online and on other online platforms,
								the clients will base their decisions on
								the photographs available on the website
								and the web publication. It is vital for
								a photograph to encapsulate the beauty,
								comfort and atmosphere that are true to
								what your business brings.
								<br />
								<br />
								Photography gives space for imagination
								and lets the customer envision the
								interior, scenery and amenities that
								your business offers, whether it’s a
								hotel, beach resort, restaurant or ice
								cream store. It is therefore important
								to invest in a professional photographer
								who can show the best side of your
								hospitality.
							</React.Fragment>
						}
					/>
				}
				textAreaRight={
					<InfoCard
						titleStyles="t-center"
						title="WHY YOU SHOULD CHOOSE MIARTMEDIA CREATIVE STUDIO?"
						text={
							<React.Fragment>
								We strive to create the quality images
								you expect. The goal is to attain what
								your want your customers to imagine
								hotel and resort, restaurant or shop to
								be. <br />
								<br />
								With a unique perspective and perception
								of the ideal composition for your
								business, you can rest assured that your
								images will be visually pleasing and
								attractive. <br />
								<br />
								We would really like to show your
								customers that your hospitality
								establishment is a one-stop destination
								for comfort leisure, providing a
								sanctuary and a place for this.
							</React.Fragment>
						}
					/>
				}
			/>
			<Parallax img={img} />
			<CommercialOption />
			<ContactSection
				img={img}
				title="regarding commercial and hospitality photography services"
				text="To contact MiArtMedia Creative Studio for real estate and rental 
                         photography work or other matters, you may reach us through our contact page or 
                         email us at info@miartmedia.com"
				button={
					<Link
						to="/contact"
						className="basic-btn"
						style={{ backgroundColor: color }}
					>
						Get in Touch
					</Link>
				}
			/>
		</main>
	);
}

export default Commercial;
