import React from 'react';

const OptionButton = ({ text, func, styles }) => {
     return (
          <div className={`optionButton ${ styles }`} onClick={ func }>
               <p id={ text.toLowerCase() } >{ text }</p>
          </div>
     )
};

export default OptionButton;