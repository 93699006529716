import React, { useState, useContext } from "react";
import { InvoiceContext } from "../../../context/context";
import { OptionButton, TextInput, EmailInput, TelInput } from "../../";
import { RiCloseFill } from "react-icons/ri";

const CustomerForm = () => {
	const {
		customerFormStatus,
		setCustomerFormStatus,
		invoiceData,
		setInvoiceData,
		customerSaved,
		setCustomerSaved,
	} = useContext(InvoiceContext);

	const [activeOptionButton, setActiveOptionButton] = useState({
		contact: "optionButton__active",
		billing: "",
	});

	const handleOptionButton = (e) => {
		if (e.target.id === "contact") {
			setActiveOptionButton({
				contact: "optionButton__active",
				billing: "",
			});
		} else {
			setActiveOptionButton({
				contact: "",
				billing: "optionButton__active",
			});
		}
	};

	const handleFormData = (e) => {
		setInvoiceData({
			...invoiceData,
			[e.target.name]: e.target.value,
		});
	};

	const handle_save_customer = () => {
		setCustomerFormStatus(!customerFormStatus);

		if (!customerSaved) {
			setCustomerSaved(!customerSaved);
		}
	};

	return (
		<React.Fragment>
			{customerFormStatus ? (
				<div className="popSmallWindow">
					<div className="formGrid">
						<div className="flex flex-al-ctr flex-jus-end">
							<OptionButton
								text="Contact"
								func={handleOptionButton}
								styles={`${activeOptionButton.contact}`}
							/>
							<OptionButton
								text="Billing"
								func={handleOptionButton}
								styles={`${activeOptionButton.billing}`}
							/>
							<RiCloseFill
								className="dashboard__icon"
								onClick={() =>
									setCustomerFormStatus(
										!customerFormStatus
									)
								}
							/>
						</div>
						{activeOptionButton.contact ? (
							<div className="formInputsGrid">
								Customer Information
								<TextInput
									name="company"
									placeholder="Company"
									value={invoiceData.company}
									onchange={handleFormData}
								/>
								<TextInput
									name="firstName"
									placeholder="First Name"
									value={invoiceData.firstName}
									onchange={handleFormData}
								/>
								<TextInput
									name="lastName"
									placeholder="Last Name"
									value={invoiceData.lastName}
									onchange={handleFormData}
								/>
								<EmailInput
									name="email"
									placeholder="Email Address"
									value={invoiceData.email}
									onchange={handleFormData}
								/>
								<TelInput
									name="phone"
									placeholder="Phone Number"
									value={invoiceData.phone}
									onchange={handleFormData}
								/>
							</div>
						) : (
							<div className="formInputsGrid">
								Billing Address
								<TextInput
									name="address1"
									placeholder="Address Line 1"
									value={invoiceData.address1}
									onchange={handleFormData}
								/>
								<TextInput
									name="city"
									placeholder="City"
									value={invoiceData.city}
									onchange={handleFormData}
								/>
								<TextInput
									name="country"
									placeholder="Country"
									value={invoiceData.country}
									onchange={handleFormData}
								/>
								<TextInput
									name="state"
									placeholder="State/Province"
									value={invoiceData.state}
									onchange={handleFormData}
								/>
								<TextInput
									name="postal"
									placeholder="Postal Code"
									value={invoiceData.postal}
									onchange={handleFormData}
								/>
							</div>
						)}
						<div className="basicForm__btn">
							<button
								className="fullWidth-btn portfolioForm__btn"
								onClick={handle_save_customer}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};

export default CustomerForm;
