import React from 'react';

const Response = ({ style, message }) => {
     return (
          <React.Fragment>
               {
                    message === 'password'
                    ? <React.Fragment>
                         <p className={`response-${ style }`}>{`Password must contain:`.toUpperCase()}</p>
                         <p className={`response-${ style }`}>minimum of 1 lower case letter</p>
                         <p className={`response-${ style }`}>minimum of 1 upper case letter</p>
                         <p className={`response-${ style }`}>minimum of 1 numeric character</p>
                         <p className={`response-${ style }`}>minimum of 1 special character</p>
                         <p className={`response-${ style }`}>must be at least 8 characters</p>
                    </React.Fragment>
                    : <p className={`response-${ style }`}>{ message.toUpperCase() }</p>
               }
          </React.Fragment>
     )
};

export default Response;