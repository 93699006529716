import React from 'react';

const CommonTable = ({ children }) => {
     return (
          <div className='commonTable'>
               <ul className='commonTable__thead'>
                    <li>image</li>
                    <li>title</li>
                    <li>category</li>
                    <li>date</li>
                    <li>action</li>               
               </ul>
               { children }
          </div>
     )
};

export default CommonTable;