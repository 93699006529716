import React from "react";
import { Helmet } from "react-helmet-async";
import {
	DblSectionMedia,
	Grid2,
	DblColumn,
	SingleImage,
	BackgroundOverlay,
	InfoCard,
	Parallax,
	ContactSection,
} from "../components/index";
import { CommercialOption } from "./index";
import { Link } from "react-router-dom";

import img from "../images/miartmedia-0732.jpg";

function Food() {
	const color = "rgb(226, 200, 176)";

	return (
		<main>
			<Helmet>
				<title>
					Food Photography Calgary | MiArtMedia Creative Studio
				</title>
				<meta
					name="description"
					content="To improve your branding and marketing, 
                    increase sales, and attract new customers, it is important to invest in 
                    quality food photography."
				/>
				<link
					rel="canonical"
					href="https://miartmedia.com/food-photography"
				/>

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://miartmedia.com/food-photography"
				/>
				<meta
					property="og:title"
					content="GET IN TOUCH | MiArtMedia Creative Studio"
				/>
				<meta
					property="og:description"
					content="To improve your branding and marketing, 
                    increase sales, and attract new customers, it is important to invest in quality 
                    food photography."
				/>
				<meta property="og:image" content={img} />

				{/* Twitter */}
				<meta
					property="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content="https://miartmedia.com/food-photography"
				/>
				<meta
					property="twitter:title"
					content="GET IN TOUCH | MiArtMedia Creative Studio"
				/>
				<meta
					property="twitter:description"
					content="To improve your branding and marketing, 
                    increase sales, and attract new customers, it is important to invest in quality 
                    food photography."
				/>
				<meta property="twitter:image" content={img}></meta>
			</Helmet>
			<DblSectionMedia
				textSide={
					<DblColumn
						title="Food Photography"
						subtitle="RESTAURANTS | HOTELS | BUSINESS | STORES"
						linkText="Learn more Home"
						to="/photography-portfolio"
						bgColor={color}
					/>
				}
				imageSide={
					<React.Fragment>
						<SingleImage
							styles="dblSection--image"
							img={img}
						/>
						<BackgroundOverlay styles="dblSection--backgroundOverlay" />
					</React.Fragment>
				}
			/>
			<Grid2
				styles="pt-10 pb-10"
				textAreaLeft={
					<InfoCard
						titleStyles="t-center"
						title="Why food photography is important for the business?"
						text={
							<React.Fragment>
								To improve your branding and marketing,
								increase sales, and attract new
								customers, it is important to invest in
								quality food photography. It will
								directly appeal to your customers,
								making them want to taste the food. It
								also helps them to make decisions when
								ordering food.
								<br />
								<br />
								With the help of professional food
								photography, you will be able to deliver
								a meaningful message and connect with
								the customers. The brand identity of
								your restaurant stands as a pillar for
								your success since you have to deal with
								strong competition. Good photography is
								one of the basic and most important
								tools for online marketing.
							</React.Fragment>
						}
					/>
				}
				textAreaRight={
					<InfoCard
						titleStyles="t-center"
						title="WHY YOU SHOULD CHOOSE MIARTMEDIA CREATIVE STUDIO?"
						text={
							<React.Fragment>
								Professional food photographer knows the
								nuances of working with products that
								most people have no idea about. These
								little secrets help him create
								appetizing images that convey the beauty
								of the food. Professional food
								photographer easily finds the most
								favorable angle, lighting, and
								skillfully balances elements in the
								frame.
								<br />
								<br />
								It is also vital to know how to capture
								fleeting moments. Some dishes need to be
								photographed quickly, as they "live"
								only a few seconds/minutes; for example,
								cappuccino very quickly loses its
								original appearance: bubbles appear on
								the skin.
								<br />
								<br />A food photographer pays great
								attention to detail, knows where to put
								something away or add, turn it over.
								These are minor changes, but they
								significantly affect the aesthetic of
								the photo. Also, a person who has never
								dealt with food photography will have a
								hard time to compose elements and create
								the right atmosphere.
							</React.Fragment>
						}
					/>
				}
			/>
			<Parallax img={img} />
			<CommercialOption />
			<ContactSection
				img={img}
				title="Regarding food photography:"
				text={
					<React.Fragment>
						To contact MiArtMedia Creative Studio for food
						photography work or other matters, you may reach
						us through our contact page or email us at{" "}
						<span style={{ color: color }}>
							info@miartmedia.com
						</span>
					</React.Fragment>
				}
				button={
					<Link
						to="/contact"
						className="basic-btn"
						style={{ backgroundColor: color }}
					>
						Get in Touch
					</Link>
				}
			/>
		</main>
	);
}

export default Food;
