import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	getAllPortfolio,
	add_project,
	edit_project,
	delete_project,
} from "../api/api";

export const getPortfolio = createAsyncThunk(
	"portfolio/getPortfolio",
	async () => {
		try {
			const { data } = await getAllPortfolio();
			return data;
		} catch (error) {
			console.log(error);
		}
	}
);

export const addNewProject = createAsyncThunk(
	"portfolio/addNewProject",
	async (data, ThunkApi) => {
		try {
			const response = await add_project(data);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const editProject = createAsyncThunk(
	"portfolio/editProject",
	async (data, ThunkApi) => {
		try {
			const response = await edit_project(data);
			if (response) return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const delProject = createAsyncThunk(
	"portfolio/deleteProject",
	async (id, ThunkApi) => {
		try {
			const response = await delete_project(id);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const portfolioSlice = createSlice({
	name: "portfolio",
	initialState: { projects: [], message: null, status: null, style: null },
	reducers: {},
	extraReducers: (builder) => {
		// GET PORTFOLIO PROJECTS
		builder.addCase(getPortfolio.pending, (state) => {
			state.status = "loading";
		});
		builder.addCase(getPortfolio.fulfilled, (state, { payload }) => {
			state.projects = payload.data;
			state.style = "message";
		});
		builder.addCase(getPortfolio.rejected, (state) => {
			state.status = "rejected";
		});
		// ADD PROJECT
		builder.addCase(addNewProject.pending, (state) => {
			state.status = "loading";
		});
		builder.addCase(addNewProject.fulfilled, (state, { payload }) => {
			state.projects = [...state.projects, payload.data];
			state.status = payload.status;
			state.message = payload.message;
			state.style = "message";
		});
		builder.addCase(
			addNewProject.rejected,
			(state, { payload, meta }) => {
				state.status = meta.requestStatus;
				state.message = payload;
				state.style = "error";
			}
		);
		// EDIT PROJECT
		builder.addCase(editProject.pending, (state) => {
			state.status = "loading";
		});
		builder.addCase(editProject.fulfilled, (state, { payload }) => {
			state.projects = state.projects.map((project) =>
				project._id === payload.data._id ? payload.data : project
			);
			state.message = payload.message;
		});
		builder.addCase(editProject.rejected, (state, { payload, meta }) => {
			state.message = payload;
		});
		// DELETE PORTFOLIO PROJECT
		builder.addCase(delProject.pending, (state) => {
			state.status = "loading";
		});
		builder.addCase(delProject.fulfilled, (state, { payload, meta }) => {
			state.projects = state.projects.filter(
				(i) => i._id !== payload.id
			);
			state.status = payload.status;
			state.message = payload.message;
			state.style = "message";
		});
		builder.addCase(delProject.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.message = payload;
			state.style = "error";
		});
	},
});
