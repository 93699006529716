import React from "react";

const BackgroundImage = ({ styles, img }) => {
	const background = {
		backgroundImage: `url('${img}')`,
		backgroundPosition: "center center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	};

	return <div className={`${styles}`} style={background}></div>;
};

export default BackgroundImage;
