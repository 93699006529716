import React from 'react';

const List = ({ children, styles }) => {
     return (
          <ul className={`list flex-col flex-al-ctr flex-jus-ctr ${styles}`}>
               { children }
          </ul>
     )
};

export default List;
