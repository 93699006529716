export const calc_total = (invoiceData, setInvoiceData) => {
	const subTotal = parseFloat(
		invoiceData.items
			.map((item) => item.amount)
			.reduce((sum, curr) => sum + curr)
	).toFixed(2);

	const discountedTotal = subTotal - (subTotal * invoiceData.discount) / 100;

	const taxedTotal =
		discountedTotal + (discountedTotal * invoiceData.tax) / 100;

	const total = parseFloat(taxedTotal).toFixed(2);

	return { subTotal, total };
};
