import React from 'react';
import { Link } from 'react-router-dom';
import { MdReadMore } from 'react-icons/md';

const BasicInfoCard = ({ title, quantity, icon, linkTo }) => {
     return (
          <div className='basicInfoCard'>
               <div className='basicInfoCard__icon'>{ icon }</div>
               <p className='basicInfoCard__title'>{ title }</p>
               <p className='basicInfoCard__quantity'>{ quantity }</p>
               <Link to={ linkTo } className='basicInfoCard__link'><MdReadMore/></Link>
               <span className='basicInfoCard__devideLine'></span>
          </div>
     )
};

export default BasicInfoCard