import React from "react";
import { Helmet } from "react-helmet-async";
import {
	Carousel,
	Intro,
	Features,
	Accordeon,
	Parallax,
	About,
	ContactSection,
} from "../components/index";
import { Link } from "react-router-dom";
import facebookImage from "../images/img-001.jpg";
import parallaxImg from "../images/Miartmedia - 0320.jpg";
import contactImg from "../images/Miartmedia - 0330.jpg";

const Home = () => {
	return (
		<main>
			<Helmet>
				<title>
					Calgary Real Estate and Interior Photography |
					MiArtMedia Creative Studio
				</title>
				<meta
					name="description"
					content="MiArtMedia Creative Studio - Calgary
                    photography professional. Real estate photography. Interior and Architectural Photography for your needs"
				/>
				<link rel="canonical" href="https://miartmedia.com" />

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://miartmedia.com" />
				<meta
					property="og:title"
					content="Calgary Real Estate and Interior Photography | MiArtMedia Creative Studio"
				/>
				<meta
					property="og:description"
					content="MiArtMedia Creative Studio - Calgary
                    photography professional. Real estate photography. Interior and Architectural Photography"
				/>
				<meta property="og:image" content={facebookImage} />

				{/* Twitter */}
				<meta
					property="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content="https://miartmedia.com"
				/>
				<meta
					property="twitter:title"
					content="Calgary Real Estate and Interior Photography | MiArtMedia Creative Studio"
				/>
				<meta
					property="twitter:description"
					content="MiArtMedia Creative Studio - Calgary
                    photography professional. Real estate photography. Interior and Architectural Photography"
				/>
				<meta
					property="twitter:image"
					content={facebookImage}
				></meta>
			</Helmet>
			<Carousel />
			<Intro />
			<Features />
			<Accordeon />
			<About />
			<Parallax img={parallaxImg} />
			<ContactSection
				img={contactImg}
				title="Contact Us Regarding Photography Services"
				text="We would love to discuss your project"
				button={
					<Link to="/contact" className="basic-btn">
						get in touch
					</Link>
				}
			/>
		</main>
	);
};

export default Home;
