import React from 'react';
import { useSelector } from 'react-redux';
import { ImBlogger } from 'react-icons/im';
import { IoImagesOutline } from 'react-icons/io5';
import { GoGraph } from 'react-icons/go';
import { RiNotification3Fill } from 'react-icons/ri';
import { BasicBoard, ChildBoardGrid, BasicInfoCard } from '../../../components';

const MainBoard = () => {
     const data = useSelector(state => state);

     return (
          <BasicBoard>
               <ChildBoardGrid styles='childBoardGridCol-1'>
                    <BasicInfoCard 
                         title='Posts'
                         quantity={data.blog.posts.length}
                         icon={ <ImBlogger /> }
                         linkTo='blog'
                    />
                    <BasicInfoCard 
                         title='projects'
                         quantity={ data.portfolio.projects.length }
                         icon={ <IoImagesOutline /> }
                         linkTo='portfolio'
                    />
                    <BasicInfoCard 
                         title='Visits'
                         quantity='0'
                         icon={ <GoGraph /> }
                         linkTo='rank'
                    />
                    <BasicInfoCard 
                         title='notifications'
                         quantity='0'
                         icon={ <RiNotification3Fill /> }
                         linkTo='notifications'
                    />
               </ChildBoardGrid>
          </BasicBoard>
     )
};

export default MainBoard;