import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container } from '../components/index';
import { Title, SubTitle, Lightbox} from '../components/index';

const Project = () => {
     const [ lightbox, setLightbox ] = useState(false);
     const [ showImage, setShowImage ] = useState('');
     const location = useLocation();
     const projectID = location.pathname.split('/')[2].split('_').join(' ');
     const [ project ] = useSelector(state => state.portfolio.projects).filter(item => item.title === projectID || item._id === projectID);
     
     const handleLightbox = (e) => {
          e.preventDefault();

          if(!lightbox) {
               setShowImage(e.target.src);
          }
          setLightbox(!lightbox);
     }

     return (
          <main style={{ position: 'relative' }}> 
               { lightbox 
                    ? <Lightbox showImage={ showImage } closeLightbox={ handleLightbox } ID={ !project ? 'loading' : project._id }/> 
                    : <Container styles='pt-10 pb-5 theme__section'>
                         <Container styles='mt-5 t-center'>
                              <Title title={ !project ? 'loading' : project.title.split('-')[0] }/>
                              <SubTitle title={ !project ? false : project.description } />
                         </Container>
                         <Container styles='mansory__gallery flex-al-ctr flex-jus-even'>
                              { !project 
                                   ? false 
                                   :project.images.map((item, key) => 
                                        <Container styles='mansory__gallery-cont' key={ key }>
                                             <img 
                                                  src={ item.image }
                                                  className='mansory__gallery-image' 
                                                  alt={ item.alt } 
                                                  onClick={ handleLightbox }
                                                  />
                                        </Container>
                              )}
                         </Container>
                    </Container>          
                }
          </main>
     )
};

export default Project;
