import React from "react";

const InvoicePreviewTable = () => {
	return (
		<div className="invoicePreviewTable">
			<ul className="invoicePreviewTable__thead">
				<li>Service</li>
				<li>Quantity</li>
				<li>Price</li>
				<li>Amount</li>
			</ul>
		</div>
	);
};

export default InvoicePreviewTable;
