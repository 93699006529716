import React from 'react';

const Parallax = ({ img }) => {

     const background = {
          backgroundImage: `url('${ img }')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
     };
     
     return (
          <div className='parallax' style={ background }></div>
     )
};

export default Parallax;
