import axios from "axios";
import { getUrl } from "../helpers/getUrl";

const API = axios.create({
	baseURL: getUrl(),
	withCredentials: true,
});

// AUTH API
export const register_user = (data) => API.post("/auth/register", data);
export const login_user = (data) => API.post("/auth/login", data);
export const isLoggedIn = () => API.get("/auth/user");
export const logout_user = () => API.delete("auth/logout");
export const forgot_password = (data) => API.put("auth/forgot-password", data);
export const reset_password = (data) =>
	API.patch(`auth/reset-password/${data.token}`, data);

// BLOG API
export const get_Posts = () => API.get("/blog");
export const add_Post = (data) => API.post("/blog", data);
export const edit_Post = (data) => API.patch(`/blog/${data.id}`, data);
export const delete_Post = (id) => API.delete(`/blog/${id}`);

// PORTFOLIO API
export const getAllPortfolio = () => API.get("/portfolio");
export const add_project = (data) => API.post("/portfolio", data);
export const edit_project = (data) =>
	API.patch(`/portfolio/${data.get("id")}`, data);
export const delete_project = (id) => API.delete(`portfolio/${id}`);

// CONTACT
export const sendEmailForm = (data) => API.post("/contact", data);

// UPDATE USER
export const update_user = (data) => API.patch("/user/update", data);
