import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
	DblSectionMedia,
	DblColumn,
	SingleImage,
	BackgroundOverlay,
	Container,
	SubTitle,
	Text,
	Response,
} from "../components/index";
import img from "../images/img-006.jpg";
import { sendEmailForm } from "../api/api";

const Contact = () => {
	const color = "rgb(242, 194, 138)";
	const [data, setData] = useState({
		name: "",
		subject: "",
		phone: "",
		email: "",
		message: "",
	});
	const [message, setMessage] = useState({ message: "", style: "" });

	useEffect(() => {
		if (message) {
			setMessage(message);
		}

		const timer = setTimeout(() => {
			setMessage({ message: "", style: "" });
		}, 5000);
		// CLEAR TIMEOUT
		return () => clearTimeout(timer);
	}, [message]);

	const handleData = (e) => {
		e.preventDefault();
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		sendEmailForm(data)
			.then((res) => {
				const { message, style } = res.data;
				setMessage({ message, style });
				setData({
					name: "",
					subject: "",
					phone: "",
					email: "",
					message: "",
				});
			})
			.catch((err) => {
				const { error } = err.response.data;
				setMessage({ message: error, style: "error" });
			});
	};

	return (
		<main>
			<Helmet>
				<title>GET IN TOUCH | MiArtMedia Creative Studio</title>
				<meta
					name="description"
					content="We are based in Calgary but we welcome any requests for 
                    international assignments. Do get in touch with us if you would like to know more "
				/>
				<link
					rel="canonical"
					href="https://miartmedia.com/contact"
				/>

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://miartmedia.com/contact/"
				/>
				<meta
					property="og:title"
					content="GET IN TOUCH | MiArtMedia Creative Studio"
				/>
				<meta
					property="og:description"
					content="We are based in Calgary but we welcome any requests 
                    for international assignments. Do get in touch with us if you would like to know more"
				/>
				<meta property="og:image" content={img} />

				{/* Twitter */}
				<meta
					property="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content="https://miartmedia.com/contact/"
				/>
				<meta
					property="twitter:title"
					content="GET IN TOUCH | MiArtMedia Creative Studio"
				/>
				<meta
					property="twitter:description"
					content="We are based in Calgary but we welcome any requests 
                    for international assignments. Do get in touch with us if you would like to know more"
				/>
				<meta property="twitter:image" content={img}></meta>
			</Helmet>
			<DblSectionMedia
				textSide={
					<DblColumn
						title="MiArtMedia Creative Studio"
						subtitle="WE’D LOVE TO HEAR FROM YOU!"
						linkText="Learn more Home"
						to="/photography_portfolio"
						bgColor={color}
					/>
				}
				imageSide={
					<React.Fragment>
						<SingleImage
							styles="dblSection--image"
							img={img}
						/>
						<BackgroundOverlay styles="dblSection--backgroundOverlay" />
					</React.Fragment>
				}
			/>
			<Container styles="theme__section flex flex-al-ctr flex-jus-ctr pt-5 pb-5">
				<form
					className="formCard cont-100 flex-col flex-al-ctr flex-jus-ctr"
					onSubmit={handleSubmit}
				>
					<SubTitle
						title="Get in Touch"
						styles="formCard__title"
					/>
					<Text
						text="We are based in Calgary but we welcome any requests for international 
                         assignments. Do get in touch with us if you would like to know more on how we 
                         can meet your photography needs! Feel free to give us a call for any urgent 
                         requests or inquiries."
						styles="formCard__text"
					/>
					<input
						type="text"
						name="name"
						value={data.name}
						onChange={handleData}
						placeholder="Full Name"
						className="formCard__input"
					/>
					<input
						type="text"
						name="subject"
						value={data.subject}
						onChange={handleData}
						placeholder="Subject"
						className="formCard__input"
					/>
					<input
						type="tel"
						name="phone"
						value={data.phone}
						onChange={handleData}
						placeholder="Phone Number"
						className="formCard__input"
					/>
					<input
						type="email"
						name="email"
						value={data.email}
						onChange={handleData}
						placeholder="Email"
						className="formCard__input"
					/>
					<textarea
						name="message"
						value={data.message}
						onChange={handleData}
						placeholder="Type a message"
						className="formCard__input"
					></textarea>
					<Response
						message={message.message}
						style={message.style}
					/>
					<input
						type="submit"
						name="submit"
						value="Send Message"
						className="basic-btn"
						style={{ backgroundColor: color }}
					/>
				</form>
			</Container>
		</main>
	);
};

export default Contact;
