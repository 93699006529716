import React from "react";

const NumberInput = ({ name, value, onchange, placeholder, min, max }) => {
	return (
		<input
			type="number"
			name={name}
			value={value}
			onChange={onchange}
			placeholder={!placeholder ? name : placeholder}
			className="textInput"
			min={min}
			max={max}
		/>
	);
};

export default NumberInput;
