import React from 'react';

const FeatureList = ({ children, styles }) => {
     return (
          <ul className={`featureList flex-col flex-al-ctr flex-jus-ctr ${styles}`}>
               { children }
          </ul>
     )
};

export default FeatureList;
