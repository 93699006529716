import React from 'react';
import { ErrorCard } from '../components/index';

const ErrorPage = () => {
     return (
          <main className='theme__section flex flex-al-ctr flex-jus-ctr h-100'>
               <ErrorCard />
          </main>
     )
};

export default ErrorPage;
