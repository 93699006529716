import React from 'react';

const InfoCard = ({ title, text, styles, titleStyles }) => {
     return (
          <div className={`infoCard ${ styles }`}>
               <h3 className={`infoCard__title ${ titleStyles }`}>{ title }</h3>
               <p className="infoCard__text">{ text }</p>
          </div>
     )
};

export default InfoCard;
