import React from 'react';

const Section = ({ styles, children }) => {
     return (
          <section className={ styles }>
               { children }
          </section>
     )
};

export default Section;
