import React, { useContext, useRef } from "react";
import { InvoiceContext } from "../../context/context";
import { RiMenuFoldLine, RiFileDownloadFill } from "react-icons/ri";
import { InvoicePreviewTable } from "..";
import { useSelector } from "react-redux";
import logo from "../../images/img-logo-bl.png";
import JsPDF from "jspdf";

const InvoicePreview = () => {
	const { user } = useSelector((state) => state.auth);
	const { previewInvoiceStatus, setPreiewInvoiceStatus, invoiceData } =
		useContext(InvoiceContext);
	const pdf = useRef();

	const downloadPDF = () => {
		const invoice = new JsPDF("p", "pt", "a4");
		invoice.html(pdf.current).then(() => {
			invoice.save("report.pdf");
		});
	};

	return (
		<>
			{previewInvoiceStatus ? (
				<div className="popWindow">
					<div className="invoicePreview">
						<div className="invoicePreview__buttons">
							<RiFileDownloadFill
								className="dashboard__icon mr--ssm"
								onClick={downloadPDF}
							/>
							<RiMenuFoldLine
								className="dashboard__icon mr--ssm"
								onClick={() =>
									setPreiewInvoiceStatus(
										!previewInvoiceStatus
									)
								}
							/>
						</div>
						<div
							ref={pdf}
							className="invoicePreview__template"
						>
							<div className="invoicePreview__firstRow">
								<div className="invoicePreview__logo">
									<img
										src={logo}
										className="invoicePreview__img"
										alt="miartmedia logo"
									/>
								</div>
								<div className="invoicePreview__company">
									<p className="invoicePreview__text">
										{user.company && user.company}
									</p>
									<p className="invoicePreview__text">
										{user.street && user.street}
									</p>
									<p className="invoicePreview__text">
										{user.city && user.city + ","}
										{user.province &&
											user.province + ","}
										{user.country &&
											user.country + ","}
										{user.postal && user.postal}
									</p>
									<p className="invoicePreview__text">
										{user.phone && user.phone}
									</p>
									<p className="invoicePreview__text">
										{user.website && user.website}
									</p>
									<p className="invoicePreview__text">
										{user.email && user.email}
									</p>
								</div>
							</div>
							<div className="invoicePreview__secondRow">
								<div className="invoicePreview__customer">
									<p className="invoicePreview__text">
										{invoiceData?.company}
									</p>
									<p className="invoicePreview__text">
										{invoiceData?.firstName}
										{invoiceData?.lastName}
									</p>
									<p className="invoicePreview__text">
										{invoiceData?.email}
									</p>
									<p className="invoicePreview__text">
										{invoiceData?.phone}
									</p>
									<p className="invoicePreview__text">
										{invoiceData?.address1}
									</p>
									<p className="invoicePreview__text">
										{invoiceData?.city},
										{invoiceData?.country},{" "}
										{invoiceData?.state},{" "}
										{invoiceData?.postal}
									</p>
								</div>
								<div className="invoicePreview__date">
									<p className="invoicePreview__text">
										<span>Invoice #:</span>
										<span>
											{
												invoiceData?.invoiceNumber
											}
										</span>
									</p>
									<p className="invoicePreview__text">
										<span>Invoice Date:</span>
										<span>
											{invoiceData?.date}
										</span>
									</p>
									<p className="invoicePreview__text">
										<span>
											Invoice Due Amount:
										</span>
										<span>
											{invoiceData?.total}
										</span>
									</p>
								</div>
							</div>
							<div className="invoicePreview__thirdRow">
								<InvoicePreviewTable />
								{invoiceData?.items.map((item) => {
									return (
										<ul
											className="invoicePreview__list"
											key={item.service}
										>
											<li>
												<p>
													{item.service}
												</p>
												<p>
													{
														item.description
													}
												</p>
											</li>
											<li>{item.quantity}</li>
											<li>${item.price}</li>
											<li>${item.amount}</li>
										</ul>
									);
								})}
							</div>
							<div className="invoicePreview__fourthRow">
								<p>Subtotal: ${invoiceData.subTotal}</p>
								<p>Discount: {invoiceData.discount}%</p>
								<p>Tax: {invoiceData.tax}%</p>
								<p>Total: ${invoiceData.total}</p>
							</div>
							<div className="invoicePreview__fifthRow">
								<p>Notes / Terms</p>
								<p>{invoiceData.notes}</p>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default InvoicePreview;
