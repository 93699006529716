import React from 'react';

const Container = ({ styles, style, children, func }) => {
     return (
          <div className={ styles } style={ style } onClick={ func }>
               { children }
          </div>
     )
};

export default Container;
