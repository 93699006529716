import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../../redux/authSlice";
import {
	BasicBoard,
	ProfileGrid,
	ImageInput,
	TextInput,
	ResponseMessage,
} from "../../../components";

const ProfileBoard = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const user_form = useRef();
	const [data, setData] = useState({});
	const [message, setMessage] = useState({});

	const handle_inputs = (e) => {
		e.preventDefault();
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const update = { ...user, ...data };
		dispatch(updateUser(update)).then((data) => {
			if (!data.error) {
				setMessage({
					text: data.payload.message,
					style: "success",
				});
				clear_form_and_data();
			} else {
				setMessage({
					text: data.payload,
					style: "error",
				});
			}

			clear_message();
		});
	};

	const clear_form_and_data = () => {
		user_form.current.reset();
		setData({});
	};

	const clear_message = () => {
		setTimeout(() => {
			setMessage({});
		}, 5000);
	};

	return (
		<BasicBoard>
			<form
				method="post"
				className="basicForm3"
				onSubmit={handleSubmit}
				ref={user_form}
			>
				<ProfileGrid>
					<section>
						<div>
							<p>User Details</p>
						</div>
						<ImageInput
							id={user?.thumbnail}
							name={user?.thumbnail}
						/>
						<TextInput
							name={"firstName"}
							value={data.value}
							onchange={handle_inputs}
							placeholder={
								!user.firstName
									? "First Name"
									: user?.firstName
							}
						/>
						<TextInput
							name={"lastName"}
							value={data.value}
							onchange={handle_inputs}
							placeholder={
								!user.lastName
									? "Last Name"
									: user?.lastName
							}
						/>
						<TextInput
							name={"email"}
							value={data.value}
							onchange={handle_inputs}
							placeholder={
								!user.email ? "Email" : user?.email
							}
						/>
						<TextInput
							name={"phone"}
							value={data.value}
							onchange={handle_inputs}
							placeholder={
								!user.phone ? "Phone" : user?.phone
							}
						/>
					</section>
					{user.access === 1 ? (
						<section>
							<div>
								<p>Company Details</p>
							</div>
							<ImageInput
								id={user?.logo}
								name={user?.logo}
							/>
							<TextInput
								name={"company"}
								value={data.value}
								onchange={handle_inputs}
								placeholder={
									!user.company
										? "Company"
										: user?.company
								}
							/>
							<TextInput
								name={"website"}
								value={data.value}
								onchange={handle_inputs}
								placeholder={
									!user.website
										? "website"
										: user?.website
								}
							/>
							<TextInput
								name={"street"}
								value={data.value}
								onchange={handle_inputs}
								placeholder={
									!user.street
										? "Street"
										: user?.street
								}
							/>
							<TextInput
								name={"city"}
								value={data.value}
								onchange={handle_inputs}
								placeholder={
									!user.city ? "City" : user?.city
								}
							/>
							<TextInput
								name={"province"}
								value={data.value}
								onchange={handle_inputs}
								placeholder={
									!user.province
										? "Province"
										: user?.province
								}
							/>
							<TextInput
								name={"country"}
								value={data.value}
								onchange={handle_inputs}
								placeholder={
									!user.country
										? "Counrty"
										: user?.country
								}
							/>
							<TextInput
								name={"postal"}
								value={data.value}
								onchange={handle_inputs}
								placeholder={
									!user.postal
										? "Postal"
										: user?.postal
								}
							/>
						</section>
					) : null}
				</ProfileGrid>
				<div className="basicForm__btn">
					<button className="fullWidth-btn portfolioForm__btn">
						Save Changes
					</button>
				</div>
			</form>
			<ResponseMessage message={message} />
		</BasicBoard>
	);
};

export default ProfileBoard;
