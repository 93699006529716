import React from "react";

const ResponseMessage = ({ message }) => {
	return (
		<p className={`response-message response-message-${message.style}`}>
			{message.text}
		</p>
	);
};

export default ResponseMessage;
