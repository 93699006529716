import React from 'react';
import { Section, Container, Title, SubTitle, Text, DoubleImage } from '../index';
import portrait from '../../images/Miartmedia - 0551.jpg';
import landscape from '../../images/Miartmedia - 0375.jpg';

const Intro = () => {
     return (
          <Section styles='theme__section mt-10 mb-30 intro'>
                    <Container styles='cont-50 flex flex-al-ctr flex-jus-st intro__left'>
                         <Container styles='cont-66 flex-col intro__left--container'>
                              <Title title='Calgary Residential, Commercial and Architectural Photography' />
                              <hr className='short-line'/>
                              <SubTitle title='Get your marketing to the next level' />
                              <Text 
                                   text='You have a real estate that you want to sell, project to captivate your potential clients & you understand the significance & strength of photograph reaching people attention? Then you’re definitely in the right place!'
                                   styles='text t-justify'
                              />
                              <Text 
                                   text='Our experience ranges across real estate and rental, interior and architectural, commercial and hospitality photography bringing value to your business.
                                        Our main goal is to make your clients to fall in love with the real estate properties at first sight.'
                                   styles='text t-justify'
                              />
                              <Text 
                                   text='Therefore, the price of photographing these residences must be amortized immediately, making every image an incentive, a marvelous push towards the surpassed goals and expectations for both parties.'
                                   styles='text t-justify'
                              />
                              <Text 
                                   text='Either it’s real estate photo shoot or commercial, MiArtMedia take into consideration the charm and great taste that is always present in a remarkable design. Our photography is just that! Classy and modern, but without losing some classicism in terms of respect for shapes and respect for good work.'
                                   styles='text t-justify'
                              />     
                         </Container>
                    </Container>
                    <Container styles='cont-50 p-rel flex flex-al-ctr intro__right'>
                         <DoubleImage img1={ portrait } img2={ landscape } />
                    </Container>
               </Section>
     )
};

export default Intro;
