import React, { useState } from "react";
import {
	DblSectionMedia,
	Grid2,
	DblColumn,
	SingleImage,
	BackgroundOverlay,
	InfoCard,
	Parallax,
	ContactSection,
	Container,
	SubTitle,
	PriceOptionsGrid,
} from "../components/index";
import { InteriorOption } from "./index";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import img from "../images/Miartmedia - 0548.jpg";
import prices from "../assets/price";

function Interior() {
	const [optionButton, setOptionButton] = useState(true);
	const color = "rgb(185, 183, 175)";

	return (
		<main>
			<Helmet>
				<title>
					Interior and Architectural Photography | MiArtMedia
					Creative Studio
				</title>
				<meta
					name="description"
					content="A type of photography related to architectural design 
                    and space, interior photography emphasizes aesthetically pleasing features of 
                    residences or commercial spaces"
				/>
				<link
					rel="canonical"
					href="https://miartmedia.com/interior-and-architectural-photography"
				/>

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://miartmedia.com/interior-and-architectural-photography"
				/>
				<meta
					property="og:title"
					content="Interior and Architectural Photography | MiArtMedia Creative Studio"
				/>
				<meta
					property="og:description"
					content="A type of photography related to architectural design 
                    and space, interior photography emphasizes aesthetically pleasing features of residences or 
                    commercial spaces"
				/>
				<meta property="og:image" content={img} />

				{/* Twitter */}
				<meta
					property="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content="https://miartmedia.com/interior-and-architectural-photography"
				/>
				<meta
					property="twitter:title"
					content="Interior and Architectural Photography | MiArtMedia Creative Studio"
				/>
				<meta
					property="twitter:description"
					content="A type of photography related to architectural design 
                    and space, interior photography emphasizes aesthetically pleasing features of residences or 
                    commercial spaces"
				/>
				<meta property="twitter:image" content={img}></meta>
			</Helmet>
			<DblSectionMedia
				textSide={
					<DblColumn
						title="Interior and Architectural Photography"
						subtitle="INTERIOR DESIGNERS | ARCHITECTS | BUILDERS | DEVELOPERS | STAGGERS"
						linkText="Learn more Home"
						to="/photography-portfolio"
						bgColor={color}
					/>
				}
				imageSide={
					<React.Fragment>
						<SingleImage
							styles="dblSection--image"
							img={img}
						/>
						<BackgroundOverlay styles="dblSection--backgroundOverlay" />
					</React.Fragment>
				}
			/>
			<Grid2
				styles="pt-10 pb-10"
				textAreaLeft={
					<InfoCard
						titleStyles="t-center"
						title="WHAT IS INTERIOR AND ARCHITECTURAL PHOTOGRAPHY?"
						text={
							<React.Fragment>
								A type of photography related to
								architectural design and space, interior
								photography emphasizes aesthetically
								pleasing features of residences or
								commercial spaces. <br />
								<br />
								It’s about working with various methods
								of lighting – from natural light to
								different combinations of flashes, to
								highlight a specific aesthetic of space.
								It’s about skillfully maneuvering the
								confined limits of space and dealing
								with angles to redefine the perspectives
								of interior spaces.
							</React.Fragment>
						}
					/>
				}
				textAreaRight={
					<InfoCard
						titleStyles="t-center"
						title="WHY YOU SHOULD CHOOSE MIARTMEDIA CREATIVE STUDIO?"
						text={
							<React.Fragment>
								We look closely to identify aspects of
								an interior that make living or
								commercial spaces visually captivating.{" "}
								<br />
								<br />
								With our experience and versatility, we
								commit to explore every part of an
								interior and make its unique character
								and style come alive.
							</React.Fragment>
						}
					/>
				}
			/>
			<Parallax img={img} />
			<Grid2
				styles="pt-5 pb-2"
				textAreaLeft={
					<button
						className="fullWidth-btn"
						style={{ backgroundColor: color }}
						onClick={() => {
							setOptionButton(true);
						}}
					>
						Click for Builders, Staggers
					</button>
				}
				textAreaRight={
					<button
						className="fullWidth-btn"
						style={{ backgroundColor: color }}
						onClick={() => {
							setOptionButton(false);
						}}
					>
						Click for Interior Designers, Architects
					</button>
				}
			/>
			<Container styles="cont-100 flex-row flex-al-ctr flex-jus-ctr pt-5 pb-5">
				<SubTitle
					styles="cont-theme t-center"
					title="As architects, construction management companies, 
                         designers, and engineers, you want to showcase your work 
                         with the best possible light and perspectives. Using showcase 
                         imagery, immediately raises the awareness of the quality 
                         associated with your brand."
				/>
			</Container>
			{optionButton === true ? (
				<PriceOptionsGrid data={prices.interior} />
			) : (
				<InteriorOption />
			)}
			<ContactSection
				img={img}
				title="regarding interior and architectural photography in calgary:"
				text="To contact MiArtMedia Creative Studio for interior and architectural 
                         photography work or other matters, you may reach us through our contact page or 
                         email us at info@miartmedia.com"
				button={
					<Link
						to="/contact"
						className="basic-btn"
						style={{ backgroundColor: color }}
					>
						Get in Touch
					</Link>
				}
			/>
		</main>
	);
}

export default Interior;
