import React from 'react';

const BasicBoard = ({ children }) => {
     return (
          <div className='basicBoard'>
               { children }
          </div>    
     )
};

export default BasicBoard