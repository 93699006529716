import React, { useState } from "react";
import {
	DblSectionMedia,
	Grid2,
	DblColumn,
	SingleImage,
	BackgroundOverlay,
	InfoCard,
	Parallax,
	ContactSection,
	Container,
	SubTitle,
	PriceOptionsGrid,
	Title,
} from "../components/index";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import prices from "../assets/price";
import img from "../images/Miartmedia - 0470.jpg";

const RealEstate = () => {
	const [optionButton, setOptionButton] = useState(true);

	return (
		<main>
			<Helmet>
				<title>
					Real Estate and Rental Photography | MiArtMedia
					Creative Studio
				</title>
				<meta
					name="description"
					content="Real estate photography is fundamental to 
                    boost property listing and real estate agent services. Especially when it 
                    comes to selling a new residential property"
				/>
				<link
					rel="canonical"
					href="https://miartmedia.com/real-estate-and-rental-photography"
				/>

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://miartmedia.com/real-estate-and-rental-photography"
				/>
				<meta
					property="og:title"
					content="Real Estate and Rental Photography | MiArtMedia Creative Studio"
				/>
				<meta
					property="og:description"
					content="Real estate photography is fundamental 
                    to boost property listing and real estate agent services. Especially when it 
                    comes to selling a new residential property"
				/>
				<meta property="og:image" content={img} />

				{/* Twitter */}
				<meta
					property="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content="https://miartmedia.com/real-estate-and-rental-photography"
				/>
				<meta
					property="twitter:title"
					content="Real Estate and Rental Photography | MiArtMedia Creative Studio"
				/>
				<meta
					property="twitter:description"
					content="Real estate photography is fundamental 
                    to boost property listing and real estate agent services. Especially when it comes 
                    to selling a new residential property"
				/>
				<meta property="twitter:image" content={img}></meta>
			</Helmet>
			<DblSectionMedia
				textSide={
					<DblColumn
						title="Real Estate and Rental Photography"
						subtitle="REAL ESTATE AGENTS | PROPERTY MANAGEMENT | HOME OWNERS | LANDLORDS"
						linkText="Learn more Home"
						to="/photography-portfolio"
						bgColor="rgb(234,224,200)"
					/>
				}
				imageSide={
					<React.Fragment>
						<SingleImage
							styles="dblSection--image"
							img={img}
						/>
						<BackgroundOverlay styles="dblSection--backgroundOverlay" />
					</React.Fragment>
				}
			/>
			<Grid2
				styles="pt-10 pb-10"
				textAreaLeft={
					<InfoCard
						titleStyles="t-center"
						title="What is real estate photography?"
						text={
							<React.Fragment>
								Real estate photography is fundamental
								to boost property listing and real
								estate agent services. Especially when
								it comes to selling a new residential
								property with the right images to
								represent it. <br />
								<br />
								Providing the perfect perspective of
								your real estate is the step to let your
								listing rise from the crowd. It should
								propose itself as something unique and
								beautiful that home buyers would love to
								own. In the long run, you will see that
								having high-quality photographs of your
								real estate listing is going to be one
								of the highlights providing you more
								customers and prospective clients.
							</React.Fragment>
						}
					/>
				}
				textAreaRight={
					<InfoCard
						titleStyles="t-center"
						title="Why MiArtMedia Creative Studio?"
						text={
							<React.Fragment>
								Our photograph speaks for itself! We
								treat each real estate photo shoot
								independently and focus on capturing the
								most impressive photos from each of the
								homes we visit. <br />
								<br />
								We ‘re passionate about residential
								photography, and this is shown in the
								finished product. We believe that a
								property should be fully visualized and
								photographs what makes it unique and
								individual.
							</React.Fragment>
						}
					/>
				}
			/>
			<Parallax img={img} />
			<Grid2
				styles="pt-5 pb-2"
				textAreaLeft={
					<button
						className="fullWidth-btn"
						onClick={() => {
							setOptionButton(true);
						}}
					>
						Click for Realtors
					</button>
				}
				textAreaRight={
					<button
						className="fullWidth-btn"
						onClick={() => {
							setOptionButton(false);
						}}
					>
						Click for Landlords, Home owners
					</button>
				}
			/>
			<Container styles="cont-100 flex-row flex-al-ctr flex-jus-ctr">
				<SubTitle
					styles="cont-theme t-center"
					title="We will create bright, airy photographs that make the best 
                         use of natural light — authentic imagery that tells a rich 
                         story emphasizing those things that make the listing special."
				/>
			</Container>
			{optionButton === true ? (
				<PriceOptionsGrid data={prices.real_estate} />
			) : (
				<PriceOptionsGrid data={prices.rental} />
			)}
			<Container styles="cont-100 pt-1 pb-2 flex flex-al-ctr flex-jus-ctr">
				<Title title="Add - Ons" styles="title-xxl" />
			</Container>
			<PriceOptionsGrid data={prices.add_on} />
			<ContactSection
				img={img}
				title="REGARDING REAL ESTATE, RENTAL, AIRBNB PHOTOGRAPHY SERVICES IN CALGARY:"
				text="To contact MiArtMedia Creative Studio for real estate and rental 
                         photography work or other matters, you may reach us through our contact page or 
                         email us at info@miartmedia.com"
				button={
					<Link to="/contact" className="basic-btn">
						Get in Touch
					</Link>
				}
			/>
		</main>
	);
};

export default RealEstate;
