import React, { useState } from 'react';
import { useLocation, Link, Outlet } from 'react-router-dom';
import { SlideMenu } from '.';
import { RiHome2Fill, RiMenuFoldLine, RiMenuLine } from 'react-icons/ri';

function Dashboard() {
     const location = useLocation();
     const [ active, setActive ] = useState(false);

     return (
          <main>
               <div className='dashboard p-rel'>
                    <div className='dashboard__section'>
                         <SlideMenu active={ active } onclick={()=> setActive(!active)}/>
                         <div className='dashboard__topline'>
                              <div className='dashboard__breadcrumbs'>
                                   <Link to='/dashboard'><RiHome2Fill /></Link>
                                   <p>{ location.pathname }</p>
                              </div>
                              <div className='dashboard__icon'>
                              { active 
                                   ? <RiMenuFoldLine className='dashboard__icon' onClick={ () => setActive(!active) }/>
                                   : <RiMenuLine className='dashboard__icon' onClick={ () => setActive(!active) }/>
                              }
                              </div>
                         </div>
                         <Outlet />
                    </div>
               </div>
          </main>
     )
};

export default Dashboard;
