import React from 'react';
import { Section, Container } from '../index';
// Double section media
const DblSectionMedia = ({ textSide, imageSide }) => {
     return (
          <Section styles='theme__section--fullscreen p-rel dblSection'>
               <Container styles='flex-col cont-50 p-rel dblSection__textSide'>
                    { textSide }
               </Container>
               <Container styles='cont-50 p-rel dblSection__imageSide'>
                    { imageSide }
               </Container>
          </Section>
     )
};

export default DblSectionMedia;
