import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav } from '../index';
import logo_bl from '../../images/img-logo-bl.png';

const Header = () => {
     const location = useLocation();
     const [ scroll, setScroll ] = useState(0);
     const [ toggle, setToggle ] = useState(false);
     const [ path ] = ['/dashboard', '/dashboard/blog', '/dashboard/portfolio', '/dashboard/notifications', '/dashboard/billing', '/dashboard/profile',
     '/dashboard/rank'].filter(item => location.pathname === item);

     useEffect(() => {
          const isScroll = (position) => {
               setScroll(position)
          }

          window.addEventListener('scroll', e => 
               isScroll(window.scrollY)
          );

          return window.removeEventListener('scroll', isScroll )
     },[scroll])

     const handleToggle = () => {
          setToggle(!toggle);
     };

     return (
          <React.Fragment>
               { location.pathname === path ? false : 
                    <header className="header">
                         <div className='header__wrapper'>
                              <div className="header__L">
                                   <Link to='/' className='header__L-link'>
                                        <img className={`header__L-logo ${ location.pathname === '/' || scroll > 10 ? 'd-none' : 'flex fadeInOpacity' }`} src={ logo_bl } alt="miartmedia creative studio" />
                                   </Link>
                                   <Link className={`header__L-link ${ scroll > 10 ? 'd-none' : 'flex fadeInOpacity' }`} to='/photography-portfolio'>Portfolio</Link>
                              </div>
                              <div className="header__R">
                                   <div className='header__R-links'>
                                        <Link className="header__R-link" to="/real-estate-and-rental-photography">Real Estate and Rental Photography</Link>
                                        <Link className="header__R-link" to="/interior-and-architectural-photography">Interior and Architectural Photography</Link>
                                        <Link className="header__R-link" to="/commercial-and-hospitality-photography">Commercial and Hospitality Photography</Link>
                                   </div>
                                   <div className='header__R-btnCont'>
                                        <div className="header__R-btn" onClick={ handleToggle }>
                                             <div className="header__R-lineT"></div>
                                             <div className="header__R-lineC">{!toggle ? 'Menu' : 'Close'}</div>
                                             <div className="header__R-lineB"></div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         { !toggle ? null : <Nav nav={ handleToggle } />}
                    </header>
               }
          </React.Fragment>
     )
};

export default Header;