import React, { useEffect, useState, useContext } from "react";
import { RiMenuFoldLine, RiEyeFill } from "react-icons/ri";
import { InvoiceContext } from "../../context/context";
import { BillingContext } from "../../context/context";
import { useSelector } from "react-redux";
import {
	AddCustomerButton,
	CustomerForm,
	InvoiceForm,
	TextInput,
	NumberInput,
	DateInput,
	CustomerCard,
	DeleteButton,
	TextAreaInput,
	InvoicePreview,
} from "../";
import { clearInvoiceForm } from "./helpers/clear-invoice-form";
import { calc_total } from "./helpers/calc-total";

const AddInvoice = () => {
	const { user } = useSelector((state) => state.auth);
	const { addFormStatus, setAddFormStatus } = useContext(BillingContext);
	const [invoiceData, setInvoiceData] = useState({
		userId: "",
		company: "",
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		address1: "",
		city: "",
		country: "",
		state: "",
		postal: "",
		invoiceNumber: "",
		date: "",
		items: [
			{
				id: 1,
				service: "",
				description: "",
				quantity: 1,
				price: 0,
				amount: 0,
			},
		],
		discount: 0,
		subTotal: 0,
		tax: 0,
		total: 0,
		notes: "",
	});
	const [customerFormStatus, setCustomerFormStatus] = useState(false);
	const [customerSaved, setCustomerSaved] = useState(false);
	const [previewInvoiceStatus, setPreiewInvoiceStatus] = useState(false);
	const [result, setResult] = useState({});

	useEffect(() => {
		const result = calc_total(invoiceData);
		setResult(result);
	}, [invoiceData]);

	const handleFormData = (e) => {
		// HANDLES THE INVOICE # AND DATE
		setInvoiceData({ ...invoiceData, [e.target.name]: e.target.value });
	};

	const handleTotalExtras = (e) => {
		setInvoiceData({ ...invoiceData, [e.target.name]: e.target.value });
	};

	const handleItemChange = (e) => {
		// HANDLES ITEMS FIELDS CHANGE AND CALCULATE THE TOTAL OF QUANTITY AND PRICE
		const id = Number.parseInt(e.target.parentNode.parentNode.id);
		const itemIndex = invoiceData.items.findIndex(
			(item) => item.id === id
		);
		invoiceData.items[itemIndex][e.target.name] = e.target.value;
		invoiceData.items[itemIndex].amount =
			invoiceData.items[itemIndex].quantity *
			invoiceData.items[itemIndex].price;
		setInvoiceData({ ...invoiceData, items: invoiceData.items });
	};

	const handleAddItem = () => {
		// ADDS NEW EMPTY ITEM TO THE LIST
		const id = Math.floor(Math.random() * 10000000);
		const newItem = {
			id,
			service: "",
			description: "",
			quantity: 1,
			price: 0,
			amount: 0,
		};
		setInvoiceData({
			...invoiceData,
			items: [...invoiceData.items, newItem],
		});
	};

	const handleDeleteItem = (e) => {
		const id = Math.floor(Math.random() * 10000000);
		if (invoiceData.items.length === 1) {
			setInvoiceData({
				...invoiceData,
				items: [
					{
						id,
						service: "",
						description: "",
						quantity: 1,
						price: 0,
						amount: 0,
					},
				],
			});
		} else {
			const id = Number.parseInt(
				e.target.parentNode.parentNode.parentNode.parentNode
					.parentNode.id
			);
			const itemIndex = invoiceData.items.findIndex(
				(item) => item.id === id
			);
			invoiceData.items.splice(itemIndex, 1);
			setInvoiceData({ ...invoiceData, items: invoiceData.items });
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		// PASS result and userId TO invoiceData before save

		const invoice = {
			...invoiceData,
			subTotal: result?.subTotal,
			total: result?.total,
			userId: user.id,
		};

		console.log(invoice);
		clearInvoiceForm(setInvoiceData, setCustomerSaved, setAddFormStatus);
	};

	return addFormStatus ? (
		<div className="popWindow">
			<InvoiceContext.Provider
				value={{
					invoiceData,
					setInvoiceData,
					customerFormStatus,
					setCustomerFormStatus,
					customerSaved,
					setCustomerSaved,
					previewInvoiceStatus,
					setPreiewInvoiceStatus,
				}}
			>
				<InvoiceForm
					buttons={
						<React.Fragment>
							<RiEyeFill
								className="dashboard__icon mr--ssm"
								onClick={() => {
									setPreiewInvoiceStatus(
										!previewInvoiceStatus
									);
									setInvoiceData({
										...invoiceData,
										subTotal: result?.subTotal,
										total: result?.total,
									});
								}}
							/>
							<RiMenuFoldLine
								className="dashboard__icon mr--ssm"
								onClick={() =>
									setAddFormStatus(!addFormStatus)
								}
							/>
						</React.Fragment>
					} // FIRST BUTTON ACTIVETE VIEW OF PDF FILE, SECOND BUTTON DEACTIVATE ADD INVOICE SCREEN
					submit={handleSubmit}
					customerForm={<AddCustomerButton />} // BUTTON ACTIVATE CUSTOMER FORM
					AddInvoiceInfo={
						<React.Fragment>
							<NumberInput
								name="invoiceNumber"
								placeholder="Invoice #"
								onchange={handleFormData}
							/>
							<DateInput func={handleFormData} />
						</React.Fragment>
					}
					customerInfo={
						<CustomerCard
							firstName={invoiceData?.firstName}
							lastName={invoiceData?.lastName}
							company={invoiceData?.company}
							email={invoiceData?.email}
							phone={invoiceData?.phone}
							address={invoiceData?.address1}
							city={invoiceData?.city}
							country={invoiceData?.country}
							postal={invoiceData?.postal}
						/>
					}
					items={invoiceData.items.map((item) => (
						<ul
							id={item.id}
							key={item.id}
							className="invoiceForm__body--items"
						>
							<li className="invoiceForm__body--doubleItem">
								<TextInput
									name="service"
									value={item.service}
									onchange={handleItemChange}
								/>
								<TextInput
									name="description"
									value={item.description}
									onchange={handleItemChange}
								/>
							</li>
							<li className="invoiceForm__body--singleItem">
								<NumberInput
									name="quantity"
									value={item.quantity}
									onchange={handleItemChange}
								/>
							</li>
							<li className="invoiceForm__body--singleItem">
								<NumberInput
									name="price"
									value={item.price}
									onchange={handleItemChange}
								/>
							</li>
							<li className="invoiceForm__body--singleItem">
								<span className="t-al-ctr">{`$ ${item.amount}`}</span>
							</li>
							<li className="invoiceForm__body--singleItem">
								<DeleteButton func={handleDeleteItem} />
							</li>
						</ul>
					))}
					addItem={handleAddItem}
					total={
						<React.Fragment>
							<p className="jus-self-end">{`$ ${result?.subTotal}`}</p>
							<span className="jus-self-end">
								Discount %
								<NumberInput
									name="discount"
									value={invoiceData.discount}
									onchange={handleTotalExtras}
								/>
							</span>
							<span className="jus-self-end">
								Tax %
								<NumberInput
									name="tax"
									value={invoiceData.tax}
									onchange={handleTotalExtras}
								/>
							</span>
							<p className="jus-self-end">{`$ ${result?.total}`}</p>
						</React.Fragment>
					}
					notes={
						<TextAreaInput
							name="notes"
							placeholder="Notes"
							func={handleFormData}
						/>
					}
				></InvoiceForm>
				<CustomerForm />
				<InvoicePreview />
			</InvoiceContext.Provider>
		</div>
	) : null;
};

export default AddInvoice;
