import React from "react";
import { RiFolderUploadFill } from "react-icons/ri";

const MultiFileInput = ({ name, onchange }) => {
	return (
		<label htmlFor={name} className="multiFileUpload">
			<input
				type="file"
				name={name}
				id={name}
				multiple
				onChange={onchange}
				className="multiFileUpload__btn"
			/>
			<RiFolderUploadFill className="multiFileUpload__icon" />
		</label>
	);
};

export default MultiFileInput;
