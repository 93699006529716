import React from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";

const ButtonLink = ({ styles, text, to }) => {
     return (
          <div className='buttonLink'>
               <a href={ to } className={`buttonLink__link ${ styles }`}>{ text }</a>
               <IoIosArrowRoundForward className='buttonLink__icon'/>
          </div>
     )
};

export default ButtonLink;
