import React from "react";

const InvoiceTable = ({ children }) => {
	return (
		<div className="invoiceTable">
			<ul className="invoiceTable__thead">
				<li>Customer</li>
				<li>Number</li>
				<li>Total</li>
				<li>DATE</li>
				<li>Due</li>
				<li>Action</li>
			</ul>
			{children}
		</div>
	);
};

export default InvoiceTable;
