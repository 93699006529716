import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	login_user,
	isLoggedIn,
	logout_user,
	register_user,
	forgot_password,
	reset_password,
	update_user,
} from "../api/api";

export const register = createAsyncThunk(
	"auth/register",
	async (user, ThunkApi) => {
		try {
			const response = await register_user(user);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const login = createAsyncThunk("auth/login", async (user, ThunkApi) => {
	try {
		const response = await login_user(user);
		return response.data;
	} catch (error) {
		return ThunkApi.rejectWithValue(
			error.response.data.error.split(",")[0]
		);
	}
});

export const isLogged = createAsyncThunk("auth/user", async (ThunkApi) => {
	try {
		const response = await isLoggedIn();
		return response.data;
	} catch (error) {
		return ThunkApi.rejectWithValue(
			error.response.data.error.split(",")[0]
		);
	}
});

export const logout = createAsyncThunk("auth/logout", async (ThunkApi) => {
	try {
		const response = await logout_user();
		return response.data;
	} catch (error) {
		return ThunkApi.rejectWithValue(
			error.response.data.error.split(",")[0]
		);
	}
});

export const forgotPassword = createAsyncThunk(
	"auth/forgotPassword",
	async (data, ThunkApi) => {
		try {
			const response = await forgot_password(data);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const resetPassword = createAsyncThunk(
	"auth/resetPassword",
	async (data, ThunkApi) => {
		try {
			const response = await reset_password(data);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const updateUser = createAsyncThunk(
	"user/update",
	async (data, ThunkApi) => {
		try {
			const response = await update_user(data);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: {},
		status: "",
		message: "",
		style: "",
		isAuthenticated: false,
	},
	reducers: {},
	extraReducers: (builder) => {
		// REGISTER
		builder.addCase(register.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
			state.message = "Register in process...";
			state.style = "message";
		});
		builder.addCase(register.fulfilled, (state, { payload, meta }) => {
			state.user = payload.user;
			state.status = meta.requestStatus;
			state.message = payload.message;
			state.style = "message";
		});
		builder.addCase(register.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.message = payload;
			state.style = "error";
		});
		// LOGIN
		builder.addCase(login.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
			state.message = "Logging User";
			state.style = "message";
		});
		builder.addCase(login.fulfilled, (state, { payload, meta }) => {
			state.user = payload.user;
			state.status = meta.requestStatus;
			state.message = "You are logged in";
			state.style = "message";
			// window.localStorage.setItem("userId", payload.user.userId);
		});
		builder.addCase(login.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.message = payload;
			state.style = "error";
			state.isAuthenticated = false;
		});
		// IS LOGGED IN
		builder.addCase(isLogged.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		builder.addCase(isLogged.fulfilled, (state, { payload, meta }) => {
			state.user = payload.user;
			state.status = meta.requestStatus;
		});
		builder.addCase(isLogged.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.error = payload ? payload : state.error;
		});
		// LOGOUT
		builder.addCase(logout.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		builder.addCase(logout.fulfilled, (state, { payload, meta }) => {
			state.user = payload.user;
			state.status = meta.requestStatus;
			state.message = payload.message;
			state.style = "error";
		});
		builder.addCase(logout.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.error = payload;
		});
		// FORGOT PASSWORD
		builder.addCase(forgotPassword.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
			state.isAuthenticated = false;
		});
		builder.addCase(
			forgotPassword.fulfilled,
			(state, { payload, meta }) => {
				state.status = meta.requestStatus;
				state.message = payload.message;
				state.style = "message";
			}
		);
		builder.addCase(
			forgotPassword.rejected,
			(state, { payload, meta }) => {
				state.status = meta.requestStatus;
				state.message = payload;
				state.style = "error";
			}
		);
		// RESET PASSWORD
		builder.addCase(resetPassword.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		builder.addCase(
			resetPassword.fulfilled,
			(state, { payload, meta }) => {
				state.status = meta.requestStatus;
				state.message = payload.message;
				state.style = "message";
			}
		);
		builder.addCase(
			resetPassword.rejected,
			(state, { payload, meta }) => {
				state.status = meta.requestStatus;
				state.message = payload;
				state.style = "error";
			}
		);
		// UPDATE USER
		builder.addCase(updateUser.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		builder.addCase(updateUser.fulfilled, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.user = payload.user;
		});
		builder.addCase(updateUser.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
		});
	},
});
