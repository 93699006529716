import React from "react";
import { Link } from "react-router-dom";
import { MdReadMore } from "react-icons/md";
import { Title, Text } from "../../index";

const BlogCard = ({
	author,
	title,
	text,
	logo,
	mainImg,
	dateDay,
	dateMonYear,
	link,
}) => {
	return (
		<div className="blogCard">
			<div className="blogCard__left">
				<div className="blogCard__imageCont">
					<img
						src={mainImg}
						alt="interior photography"
						className="blogCard__image"
					/>
				</div>
				<div className="blogCard__dateSocial flex">
					<div className="blogCard__dateSocial-date">
						<p className="blogCard__dateSocial-date--number">
							{dateDay}
						</p>
						<p className="blogCard__dateSocial-date--month">
							{dateMonYear}
						</p>
					</div>
				</div>
			</div>
			<div className="blogCard__right">
				<Title styles="blogCard__title" title={title} />
				<Text styles="blogCard__text" text={text} />
			</div>
			<Link className="blogCard__button" to={link}>
				<MdReadMore className="blogCard__button-icon" />
			</Link>
		</div>
	);
};

export default BlogCard;
