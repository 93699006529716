import React from "react";
import { Link } from "react-router-dom";
import { RiDashboardFill } from "react-icons/ri";
import { ImBlogger } from "react-icons/im";
import { IoImagesOutline } from "react-icons/io5";
import {
	RiMoneyDollarBoxFill,
	RiNotification3Fill,
	RiLogoutBoxRLine,
} from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { GoGraph } from "react-icons/go";
import { GiReturnArrow } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/authSlice";

const SlideMenu = ({ active, onclick }) => {
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const location = useLocation();
	const menu = [
		{ link: "/dashboard", icon: <RiDashboardFill />, text: "Dashboard" },
		{ link: "/dashboard/blog", icon: <ImBlogger />, text: "Blog" },
		{
			link: "/dashboard/portfolio",
			icon: <IoImagesOutline />,
			text: "Portfolio",
		},
		{
			link: "/dashboard/billing",
			icon: <RiMoneyDollarBoxFill />,
			text: "Billing",
		},
		{
			link: "/dashboard/notifications",
			icon: <RiNotification3Fill />,
			text: "Notifications",
		},
		{ link: "/dashboard/profile", icon: <CgProfile />, text: "Profile" },
		{ link: "/dashboard/rank", icon: <GoGraph />, text: "Rank" },
	];

	return (
		<div
			className={`slideMenu ${
				active ? false : "slideMenu__notactive"
			}`}
		>
			<p className="slideMenu__component">
				Hello,
				<span>
					{!user.firstName && !user.lastName
						? "User"
						: user?.firstName + " " + user?.lastName}
				</span>
			</p>
			{menu.map((item) => (
				<Link
					key={item.text}
					to={item.link}
					className={`slideMenu__component ${
						location.pathname === item.link
							? "slideMenu__active"
							: null
					}`}
					onClick={onclick}
				>
					{item.icon}
					<span>{item.text}</span>
				</Link>
			))}
			<p
				className="slideMenu__component"
				onClick={() => dispatch(logout())}
			>
				<RiLogoutBoxRLine />
				<span>Logout</span>
			</p>
			<Link to="/" className="slideMenu__component">
				<GiReturnArrow />
				<span>Main Page</span>
			</Link>
		</div>
	);
};

export default SlideMenu;
