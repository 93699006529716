import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	TextInput,
	MultiFileInput,
	ResponseMessage,
} from "../../../components/index";
import { RiMenuFoldLine } from "react-icons/ri";
import { editProject } from "../../../redux/portfolioSlice";

const EditProject = ({ active, onclick, sendFormState, projectId }) => {
	const dispatch = useDispatch();
	const portfolio = useSelector((state) => state.portfolio);
	const [project] = portfolio.projects.filter((i) => i._id === projectId);
	const [formData, setFormData] = useState({
		userId: "",
		title: "",
		description: "",
		category: "",
		images: [],
	});

	const [message, setMessage] = useState({});

	useEffect(() => {
		if (project) {
			const fetchImages = async () => {
				const files = await Promise.all(
					project.images.map(async (url) => {
						const res = await fetch(url.image);
						const blob = await res.blob();
						return new File([blob], url.image.split("@")[1], {
							type: blob.type,
						});
					})
				);
				setFormData({
					userId: project.userId,
					title: project.title,
					description: project.description,
					category: project.category,
					images: files,
				});
			};
			fetchImages();
		}
	}, [project]);

	const handleFormData = (e) => {
		e.preventDefault();
		if (e.target.type === "file") {
			setFormData({
				...formData,
				[e.target.name]: [...formData.images, ...e.target.files],
			});
		} else {
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const oldImg = project.images.map((i) => i.image); // GET OLD IMAGES
		const newData = new FormData(); // CREATE NEW FORM DATA TO SEND WITH IMAGE FILES

		Object.values(formData.images).map((i) => newData.append("img", i));

		newData.append("userId", formData.userId);
		newData.append("title", formData.title);
		newData.append("description", formData.description);
		newData.append("category", formData.category);
		newData.append("oldImg", oldImg);
		newData.append("id", projectId);

		dispatch(editProject(newData)).then((data) => {
			if (!data.error) {
				setMessage({
					text: data.payload.message,
					style: "success",
				});
				clearFormData();
			} else {
				setMessage({
					text: data.payload,
					style: "error",
				});
				clear_message();
			}
		});
	};

	const clearFormData = () => {
		clear_message();
		setFormData({
			userId: "",
			title: "",
			description: "",
			category: "",
			images: [],
		});
		sendFormState(false);
	};

	const clear_message = () => {
		setTimeout(() => {
			setMessage({});
		}, 5000);
	};

	const delete_image = (e) => {
		e.preventDefault();

		setFormData({
			...formData,
			images: Object.values(formData.images).filter(
				(i) => i.name !== e.target.name
			),
		});
	};

	const replace_name_of_image = (e) => {
		e.preventDefault();

		setFormData({
			...formData,
			images: Object.values(formData.images).map((i) => {
				if (i.name === e.target.name) {
					const newFile = new File(
						[i],
						`${e.target.value}.jpg`,
						{
							type: i.type,
						}
					);
					return (i = newFile);
				} else {
					return i;
				}
			}),
		});
	};

	return (
		<React.Fragment>
			{active ? (
				<div className="popWindow">
					<form
						method="post"
						encType="multipart/form-data"
						className="basicForm2"
						onSubmit={handleSubmit}
					>
						<div className="basicForm__topBar">
							<RiMenuFoldLine
								className="dashboard__icon portfolioForm__icon"
								onClick={onclick}
							/>
						</div>
						<div className="basicForm__textInputs">
							<TextInput
								name="title"
								value={formData.title}
								onchange={handleFormData}
							/>
							<TextInput
								name="description"
								value={formData.description}
								onchange={handleFormData}
							/>
							<TextInput
								name="category"
								value={formData.category}
								onchange={handleFormData}
							/>
						</div>
						<div className="basicForm__body">
							<MultiFileInput
								name="images"
								onchange={handleFormData}
							/>
						</div>
						<ul className="listGallery">
							{formData.images &&
								Object.values(formData.images).map(
									(i) => (
										<li
											key={i.name}
											className="listGallery__item"
										>
											<img
												src={URL.createObjectURL(
													i
												)}
												alt={i.name}
												name={i.name}
												className="listGallery__img"
												style={{
													width: "10rem",
													height: "10rem",
												}}
												onClick={
													delete_image
												}
											/>
											<TextInput
												name={i.name}
												onBlur={
													replace_name_of_image
												}
											/>
										</li>
									)
								)}
						</ul>
						<div className="basicForm__btn">
							<button className="fullWidth-btn portfolioForm__btn">
								Save Changes
							</button>
						</div>
					</form>
					<ResponseMessage message={message} />
				</div>
			) : (
				false
			)}
		</React.Fragment>
	);
};

export default EditProject;
