import React, { useEffect, useState } from "react";
import { GridFlex, PriceCard } from "../index";

const PriceOptionsGrid = ({ data }) => {
	const [formData, setFormData] = useState([]);

	useEffect(() => {
		if (data) {
			setFormData(data);
		}
	}, [data]);

	return (
		<React.Fragment>
			<GridFlex styles="pt-2 pb-10">
				{formData &&
					formData.map((i) => (
						<PriceCard
							key={i.title}
							title={i.title}
							subtitle={i.subtitle}
							price={i.price}
							children={
								<React.Fragment>
									{i.disc_1 && <li>{i.disc_1}</li>}
									{i.disc_2 && <li>{i.disc_2}</li>}
									{i.disc_3 && <li>{i.disc_3}</li>}
									{i.disc_4 && <li>{i.disc_4}</li>}
									{i.disc_5 && <li>{i.disc_5}</li>}
									{i.disc_6 && <li>{i.disc_6}</li>}
								</React.Fragment>
							}
						/>
					))}
			</GridFlex>
		</React.Fragment>
	);
};

export default PriceOptionsGrid;
