import React from 'react';

const Text = ({ styles, text }) => {
     return (
          <div>
               <p className={ styles }>{ text }</p>
          </div>
     )
};

export default Text;
