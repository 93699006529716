import React from 'react';
import { Circle } from '../index';

const DoubleImage = ({ img1, img2 }) => {

     const portrait = {
          backgroundImage: `url('${ img1 }')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
     };

     const landscape = {
          backgroundImage: `url('${ img2 }')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
     };

     return (
          <div className='doubleImage'>
               <div className='doubleImage__portrait' style={ portrait }></div>
               <div className='doubleImage__landscape' style={ landscape }></div>
               <Circle styles='doubleImage__circle' />
          </div>
     )
};

export default DoubleImage;
