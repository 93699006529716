import React, { useRef } from "react";
import { RiImageAddFill } from "react-icons/ri";

const ImageInput = ({ id, name }) => {
	const imgInput = useRef();

	return (
		<div className="imgInput" onClick={() => imgInput.current.click()}>
			<input
				ref={imgInput}
				type="file"
				className="imgInput__input"
				name={name}
				id={id}
			/>
			<RiImageAddFill className="imgInput__icon" />
		</div>
	);
};

export default ImageInput;
