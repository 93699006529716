import React, { useState } from "react";
import { BillingContext } from "../../../context/context";

import {
	BasicBoard,
	ChildBoardGrid,
	TextInput,
	InvoiceTable,
	AddButton,
	EditButton,
	DeleteButton,
	AddInvoice,
	UpdateInvoice,
} from "../../../components";

const BillingBoard = () => {
	const [addFormStatus, setAddFormStatus] = useState(false);
	const [editFormStatus, setEditFormStatus] = useState(false);
	const invoiceID = 2424242;

	const handleSearch = () => {
		console.log({ action: "handle search" });
	};

	return (
		<BasicBoard>
			<BillingContext.Provider
				value={{
					addFormStatus,
					setAddFormStatus,
					editFormStatus,
					setEditFormStatus,
				}}
			>
				<ChildBoardGrid styles="childBoardGridRow-1">
					<div className="grid-col-90-10">
						<TextInput
							name="search"
							onchange={handleSearch}
						/>
						<AddButton
							func={() => setAddFormStatus(!addFormStatus)}
						/>
					</div>
					<InvoiceTable>
						<ul className="invoiceTable__tbody">
							<li>customer </li>
							<li>{`#8734`}</li>
							<li>{`$234`}</li>
							<li>2022-02-01</li>
							<li>in 15 days</li>
							<li className="flex flex-jus-even">
								<EditButton
									func={() =>
										setEditFormStatus(
											!editFormStatus
										)
									}
								/>
								<DeleteButton
									func={() => {
										console.log("action");
									}}
								/>
							</li>
						</ul>
					</InvoiceTable>
					<div className="flex flex-al-ctr flex-jus-ctr">
						Pages
					</div>
				</ChildBoardGrid>
				<AddInvoice />
				<UpdateInvoice invoiceID={invoiceID} />
			</BillingContext.Provider>
		</BasicBoard>
	);
};

export default BillingBoard;
