import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	BasicBoard,
	ChildBoardGrid,
	AddButton,
	EditButton,
	DeleteButton,
	TextInput,
	CommonTable,
} from "../../../components/index";
import { AddPost, EditPost } from "../../index";
import { deletePost } from "../../../redux/blogSlice";
import moment from "moment";

const BlogBoard = () => {
	const dispatch = useDispatch();
	const blog = useSelector((state) => state.blog);
	const [addForm, setAddForm] = useState(false);
	const [editForm, setEditForm] = useState(false);
	const [postId, setPostId] = useState("");
	const regex = /src=["']([^"']+)/g;

	const checkActiveStatus = (state) => {
		setTimeout(() => {
			setAddForm(state);
			setEditForm(state);
		}, 1000);
	};

	const handleSearchCategory = (e) => {
		e.preventDefault();
	};

	const handlePostEdit = (id) => {
		setPostId(id);
		setEditForm(!editForm);
	};

	return (
		<BasicBoard>
			<ChildBoardGrid styles="childBoardGridRow-1">
				<div className="grid-col-90-10">
					<TextInput
						name="search"
						onchange={handleSearchCategory}
					/>
					<AddButton func={() => setAddForm(!addForm)} />
				</div>
				<CommonTable>
					{blog.posts
						.map((i) => (
							<ul
								className="commonTable__tbody"
								key={i._id}
							>
								<li>
									<img
										src={i.body
											.match(regex)[0]
											.substring(5)}
										alt=""
									/>
								</li>
								<li>{i.title}</li>
								<li>{i.category}</li>
								<li>{moment(i.createdAt).fromNow()}</li>
								<li className="flex flex-jus-even">
									<EditButton
										func={() =>
											handlePostEdit(i._id)
										}
									/>
									<DeleteButton
										func={() =>
											dispatch(
												deletePost(i._id)
											)
										}
									/>
								</li>
							</ul>
						))
						.reverse()}
				</CommonTable>
			</ChildBoardGrid>
			<AddPost
				active={addForm}
				onclick={() => setAddForm(!addForm)}
				sendActiveStatus={checkActiveStatus}
			/>
			<EditPost
				active={editForm}
				onclick={() => setEditForm(!editForm)}
				postId={postId}
				sendActiveStatus={checkActiveStatus}
			/>
		</BasicBoard>
	);
};

export default BlogBoard;
