import React from 'react';
import { RiDeleteBin6Fill } from 'react-icons/ri';

const DeleteButton = ({ func }) => {
  return (
     <div className='flex flex-al-ctr flex-jus-ctr'>
          <RiDeleteBin6Fill className='deleteButton' onClick={ func } />
     </div>
  )
};

export default DeleteButton;