import React from 'react';

const Circle = ({ styles }) => {

     return (
          <div className={`circle ${ styles }`}></div>
     )
};

export default Circle;
