import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Post() {
	const { id } = useParams();
	const [post] = useSelector((state) => state.blog.posts).filter(
		(i) => i.title.toLowerCase() === id.replace(/_/g, " ")
	);

	const handleSrc = (src) => {
		const regex = /src=["']([^"']+)/g;
		return src.match(regex)[0].substring(5);
	};

	const handleParagraph = (par) => {
		const regex = /<p.*?>(.*?)<\/p>/g;
		return par.match(regex)[0].replace(/(<([^>]+)>)/gi, "");
	};

	return (
		<React.Fragment>
			{post ? (
				<main className="theme__section--fullwidth">
					<Helmet>
						<title>{post.title}</title>
						<meta
							name="description"
							content={`${handleParagraph(
								post.body
							).substring(0, 160)}..`}
						/>
						<link
							rel="canonical"
							href={`https://miartmedia.com/${id}`}
						/>
					</Helmet>
					<div className="post">
						<div className="post__imgCont">
							<img
								src={handleSrc(post.body)}
								alt={post.title}
							/>
						</div>
						<div className="post__body theme__section">
							<div className="post__header ">
								<p>{post.category}</p>
								<h1>{post.title}</h1>
								<h2>{post.subtitle}</h2>
							</div>
							<div className="post__text">
								<div
									dangerouslySetInnerHTML={{
										__html: post.body,
									}}
								/>
							</div>
						</div>
					</div>
				</main>
			) : (
				"Loading"
			)}
		</React.Fragment>
	);
}

export default Post;
