const prices = {
	rental: [
		{
			title: "Standard Package",
			subtitle: "Photo Package",
			price: 225,
			disc_1: "Great choice for small places or condos",
			disc_2: "up to 20 Digital Photos",
			disc_3: "Professionally Edited",
			disc_4: "Print & Web Size",
			disc_5: "Interior & Exterior Photos",
			disc_6: "1.5 hour",
		},
		{
			title: "Premium Package",
			subtitle: "Photo Package",
			price: 350,
			disc_1: "Perfect for property under 2500 sq.ft",
			disc_2: "up to 40 Digital Photos",
			disc_3: "Professionally Edited",
			disc_4: "Print & Web Size",
			disc_5: "Interior & Exterior Photos",
			disc_6: "2 - 2.5 hours",
		},
	],
	real_estate: [
		{
			title: "Starter Package",
			subtitle: "Photo Package",
			price: 140,
			disc_1: "Great choice for small places or condos",
			disc_2: "up to 20 Digital Images",
			disc_3: "Professionally Edited",
			disc_4: "Turn around time 12-24h",
			disc_5: "Interior & Exterior Photos",
			disc_6: "1 hour",
		},
		{
			title: "Standard Package",
			subtitle: "Photo Package",
			price: 165,
			disc_1: "Perfect for property under 2500 sq.ft",
			disc_2: "up to 30 Digital Images",
			disc_3: "Professionally Edited",
			disc_4: "Turn around time 12-24h",
			disc_5: "Interior & Exterior Photos",
			disc_6: "1.5 hour",
		},
		{
			title: "Premium Package",
			subtitle: "Photo Package",
			price: 185,
			disc_1: "Best option for any real estate listing",
			disc_2: "up to 40 Digital Images",
			disc_3: "Professionally Edited",
			disc_4: "Turn around time 12-24h",
			disc_5: "Interior & Exterior Photos",
			disc_6: "1.5 - 2 hours",
		},
	],
	add_on: [
		{
			title: "Travel",
			subtitle: "Extra",
			price: 0.54,
			disc_1: "Out of Town fees - .54c per km",
		},
		{
			title: "Sky Replacement",
			subtitle: "Extra",
			price: 20,
			disc_1: "Exterior Images sky replacement",
		},
		{
			title: "Exterior",
			subtitle: "Photo Package",
			price: 120,
			disc_1: "5-10 Images",
		},
	],
	interior: [
		{
			title: "Starter Package",
			subtitle: "Photo Package",
			price: 235,
			disc_1: "Great choice for small places or condos",
			disc_2: "up to 20 Digital Images",
			disc_3: "Professionally Edited",
			disc_4: "Turn around time 12-24h",
			disc_5: "Interior & Exterior Photos",
			disc_6: "1 hour",
		},
		{
			title: "Standard Package",
			subtitle: "Photo Package",
			price: 320,
			disc_1: "Perfect for property under 2500 sq.ft",
			disc_2: "up to 30 Digital Images",
			disc_3: "Professionally Edited",
			disc_4: "Turn around time 12-24h",
			disc_5: "Interior & Exterior Photos",
			disc_6: "1.5 hour",
		},
		{
			title: "Premium Package",
			subtitle: "Photo Package",
			price: 410,
			disc_1: "Best option for any size homes",
			disc_2: "up to 40 Digital Images",
			disc_3: "Professionally Edited",
			disc_4: "Turn around time 12-24h",
			disc_5: "Interior & Exterior Photos",
			disc_6: "1.5 - 2 hours",
		},
	],
};

export default prices;
