import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import '../../../../node_modules/quill/dist/quill.snow.css';

export const QuillEditor = ({ quillBody, quillValue }) => {
     const placeholder = 'Type some story here...';
     const { quill, quillRef } = useQuill({ placeholder });

     useEffect(() => {
          
          if (quill) {
               if ( quillValue ) {
                    quill.clipboard.dangerouslyPasteHTML(quillValue);
               }
               
               quill.on('text-change', (delta, oldDelta, source) => {
                    if (source === 'user') {
                         quillBody(quill.root.innerHTML); 
                    };
               });
          }
          // eslint-disable-next-line
        }, [quill]);

     return (
          <React.Fragment>
               <div ref={quillRef} />
          </React.Fragment>
     )
};