import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import {
	DblSectionMedia,
	DblColumn,
	SingleImage,
	BackgroundOverlay,
	ContactSection,
	GridFlex,
	PortfolioCard,
} from "../components/index";

import img from "../images/img-002.jpg";
import img2 from "../images/img-009.jpg";

const PortfolioFilter = ({ filter }) => {
	return (
		<GridFlex styles="pt-2 pb-2">
			<p className="filterItem" onClick={filter} id="all">
				all
			</p>
			<p className="filterItem" onClick={filter} id="real estate">
				real estate
			</p>
			<p className="filterItem" onClick={filter} id="rental">
				rental
			</p>
			<p className="filterItem" onClick={filter} id="interior">
				interior
			</p>
			<p className="filterItem" onClick={filter} id="architectural">
				architectural
			</p>
			<p className="filterItem" onClick={filter} id="commercial">
				commercial
			</p>
			<p className="filterItem" onClick={filter} id="food">
				food
			</p>
		</GridFlex>
	);
};

function Portfolio() {
	const color = "rgb(189, 175, 143)";
	const data = useSelector((state) => state.portfolio.projects);

	const [filter, setFilter] = useState("");

	const handleFilter = (e) => {
		e.preventDefault();
		e.target.id === "all" ? setFilter("") : setFilter(e.target.id);
	};

	return (
		<main>
			<Helmet>
				<title>
					Photography Portfolio | MiArtMedia Creative Studio
				</title>
				<meta
					name="description"
					content="MiArtMedia Creative Studio highlights portfolio"
				/>
				<link
					rel="canonical"
					href="https://miartmedia.com/photography-portfolio"
				/>

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://miartmedia.com/photography-portfolio"
				/>
				<meta
					property="og:title"
					content="Photography Portfolio | MiArtMedia Creative Studio"
				/>
				<meta
					property="og:description"
					content="MiArtMedia Creative Studio highlights portfolio"
				/>
				<meta property="og:image" content={img} />

				{/* Twitter */}
				<meta
					property="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content="https://miartmedia.com/photography-portfolio"
				/>
				<meta
					property="twitter:title"
					content="Photography Portfolio | MiArtMedia Creative Studio"
				/>
				<meta
					property="twitter:description"
					content="MiArtMedia Creative Studio highlights portfolio"
				/>
				<meta property="twitter:image" content={img}></meta>
			</Helmet>
			<DblSectionMedia
				textSide={
					<DblColumn
						title="Photgraphy Portfolio"
						subtitle="MiArtMedia Creative Studio"
						linkText="Get in Touch"
						to="/contact"
						bgColor={color}
					/>
				}
				imageSide={
					<React.Fragment>
						<SingleImage
							styles="dblSection--image"
							img={img}
						/>
						<BackgroundOverlay styles="dblSection--backgroundOverlay" />
					</React.Fragment>
				}
			/>
			<PortfolioFilter filter={handleFilter} />
			<GridFlex styles="theme__section p-rel flex flex-al-ctr flex-jus-ctr">
				{data
					.filter((item) =>
						filter
							? item.category === filter.toLowerCase()
							: item
					)
					.map((item) => (
						<PortfolioCard
							key={item._id}
							img={item.images[0].image}
							text={item.title.split("-")[0]}
							to={`/photography-portfolio/${
								item.title
									? item.title.split(" ").join("_")
									: item._id
							}`}
						/>
					))
					.reverse()}
			</GridFlex>
			<ContactSection
				img={img2}
				title="REGARDING PHOTOGRAPHY SERVICES:"
				text={
					<React.Fragment>
						To contact MiArtMedia Creative Studio for
						photography services or other matters, you may
						reach us through our contact page or email us at
						info@miartmedia.com
					</React.Fragment>
				}
				button={
					<Link
						to="/contact"
						className="basic-btn"
						style={{ backgroundColor: color }}
					>
						Get in Touch
					</Link>
				}
			/>
		</main>
	);
}

export default Portfolio;
