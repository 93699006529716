import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
	TextInput,
	MultiFileInput,
	ResponseMessage,
} from "../../../components/index";
import { RiMenuFoldLine } from "react-icons/ri";
import { addNewProject } from "../../../redux/portfolioSlice";

const AddProject = ({ active, onclick, sendFormState }) => {
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		title: "",
		description: "",
		category: "",
		images: [],
	});

	const [message, setMessage] = useState({});

	const handleFormData = (e) => {
		e.preventDefault();
		if (e.target.type === "file") {
			setFormData({
				...formData,
				[e.target.name]: [...formData.images, ...e.target.files],
			});
		} else {
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const data_to_send = new FormData(); // CREATE NEW FORM DATA TO SEND WITH IMAGE FILES
		Object.values(formData.images).map((i) =>
			data_to_send.append("img", i)
		);
		data_to_send.append("title", formData.title);
		data_to_send.append("description", formData.description);
		data_to_send.append("category", formData.category);

		dispatch(addNewProject(data_to_send)).then((data) => {
			if (!data.error) {
				setMessage({
					text: data.payload.message,
					style: "success",
				});
				clearFormData();
			} else {
				setMessage({
					text: data.payload,
					style: "error",
				});
				clear_message();
			}
		});
	};

	const clearFormData = () => {
		clear_message();
		setFormData({ title: "", description: "", category: "", images: [] });
		sendFormState(false);
	};

	const clear_message = () => {
		setTimeout(() => {
			setMessage({});
		}, 5000);
	};

	const replace_name_of_image = (e) => {
		e.preventDefault();

		setFormData({
			...formData,
			images: Object.values(formData.images).map((i) => {
				if (i.name === e.target.name) {
					const newFile = new File(
						[i],
						`${e.target.value}.jpg`,
						{
							type: i.type,
						}
					);
					return (i = newFile);
				} else {
					return i;
				}
			}),
		});
	};

	const delete_image = (e) => {
		e.preventDefault();

		setFormData({
			...formData,
			images: Object.values(formData.images).filter(
				(i) => i.name !== e.target.name
			),
		});
	};

	return (
		<React.Fragment>
			{active ? (
				<div className="popWindow">
					<form
						method="post"
						encType="multipart/form-data"
						className="basicForm2"
						onSubmit={handleSubmit}
					>
						<div className="basicForm__topBar">
							<RiMenuFoldLine
								className="dashboard__icon portfolioForm__icon"
								onClick={onclick}
							/>
						</div>
						<div className="basicForm__textInputs">
							<TextInput
								name="title"
								value={formData.title}
								onchange={handleFormData}
							/>
							<TextInput
								name="description"
								value={formData.description}
								onchange={handleFormData}
							/>
							<TextInput
								name="category"
								value={formData.category}
								onchange={handleFormData}
							/>
						</div>
						<div className="basicForm__body">
							<MultiFileInput
								name="images"
								onchange={handleFormData}
							/>
						</div>
						<ul className="listGallery">
							{formData.images &&
								Object.values(formData.images).map(
									(i) => (
										<li
											key={i.name}
											className="listGallery__item"
										>
											<img
												src={URL.createObjectURL(
													i
												)}
												alt={i.name}
												name={i.name}
												className="listGallery__img"
												style={{
													width: "10rem",
													height: "10rem",
												}}
												onClick={
													delete_image
												}
											/>
											<TextInput
												name={i.name}
												onBlur={
													replace_name_of_image
												}
											/>
										</li>
									)
								)}
						</ul>
						<div className="basicForm__btn">
							<button className="fullWidth-btn portfolioForm__btn">
								Save Changes
							</button>
						</div>
					</form>
					<ResponseMessage message={message} />
				</div>
			) : (
				false
			)}
		</React.Fragment>
	);
};

export default AddProject;
