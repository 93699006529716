import React from 'react';
import { BackgroundImage, BackgroundOverlay, Title, SubTitle, Container } from '../index';



const ContactSection = ({ img, title, text, button }) => {
     return (
          <div className='theme__section--fullscreen p-rel contact'>
               <BackgroundImage styles='p-abs contact__background' img={ img }/>
               <BackgroundOverlay styles='contact__overlay'/>
               <Container styles='p-ctr flex-col flex-jus-ctr flex-al-ctr contact__content'>
                    <Title title={ title } styles='contact__content--title t-center'/>
                    <SubTitle styles='contact__content--subtitle t-center' title={ text }/>
                    { button }
               </Container>
          </div>
     )
};

export default ContactSection;
