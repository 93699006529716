import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiMenuFoldLine } from 'react-icons/ri';
import { TextInput } from '../../../components/index';
import { QuillEditor } from './QuillEditor';
import { addPost } from '../../../redux/blogSlice';

const AddPost = ({ active, onclick, sendActiveStatus }) => {
     const dispatch = useDispatch();
     const blog = useSelector(state => state.blog);
     const user = useSelector(state => state.auth.user);
     const [ formData, setFormData ] = useState({ title: '', subtitle: '', category: '', body: '', user: '', thumbnail: ''});
     const [ message, setMessage ] = useState({ text: '', style: '', status: ''});

     useEffect(() => {
          if (blog) setMessage({text: blog.message, style: blog.style, status: blog.status});
          const timer = setTimeout(() => {
               setMessage({ text: '', style: '', status: ''});
          }, 5000);
          return () => clearTimeout(timer);
     },[blog]);

     useEffect(() => {
          if(user) setFormData(formData => formData = { ...formData, user: user?.name, thumbnail: user?.thumbnail });
     }, [user]);
     
     const handleFormData = (e) => {
          setFormData({ ...formData, [e.target.name]: e.target.value });
     };

     const handleQuillBody = (value) => {
          setFormData({ ...formData, body: value });
     };
          
     const handleSubmit = (e) => {
          e.preventDefault();
          dispatch(addPost(formData)).then(req => {
               if (req.meta.requestStatus === 'fulfilled'){
                    clearFormData();
               }
          });
     };

     const clearFormData = () => {
          setFormData({ title: '', subtitle: '', category: '', body: '' });
          sendActiveStatus(false);
     };
     
     return (
          <React.Fragment>
               {
                    active ?
                         <div className='popWindow'>
                              <form method="post" className='basicForm' onSubmit={ handleSubmit }>
                                   <div className='basicForm__topBar'>
                                        <RiMenuFoldLine className='dashboard__icon' onClick={ onclick }/>
                                   </div>
                                   <div className='basicForm__textInputs'>
                                        <TextInput 
                                             name='title'
                                             value={ formData.title }
                                             onchange={ handleFormData }
                                        />
                                        <TextInput 
                                             name='subtitle'
                                             value={ formData.subtitle }
                                             onchange={ handleFormData }
                                        />
                                        <TextInput 
                                             name='category'
                                             value={ formData.category }
                                             onchange={ handleFormData }
                                        />
                                   </div>
                                   <div className='basicForm__body'>
                                        <QuillEditor quillBody={ handleQuillBody }/>
                                   </div>
                                   <div className='basicForm__message'>
                                        <p className={`response response-${ message.style }`}>{ message.text }</p>
                                   </div>
                                   <div className='basicForm__btn'>
                                        <button className='fullWidth-btn portfolioForm__btn'>Save Changes</button>
                                   </div>
                              </form>
                         </div>
                    : false
               }    
          </React.Fragment>  
     )
};

export default AddPost;