import React, { useState, useEffect } from 'react';
import { Container, Text, Response } from '../components/index';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../redux/authSlice';

function ForgotPassword() {
     const color = 'rgb(234,224,200)';
     const dispatch = useDispatch();
     const auth = useSelector(state => state.auth);
     const [ data, setData ] = useState({ email: ''});
     const [ message, setMessage ] = useState({ text: '', style: '' });

     useEffect(() => {
          if (auth) setMessage({ text: auth.message, style: auth.style }) 
          const timer = setTimeout(() => {
               setMessage({ text: '', style: '' });
          }, 5000);
          // CLEAR TIMEOUT
          return () => clearTimeout(timer);
     }, [auth]);

     const handleData = (e) => {
          e.preventDefault();
          setData({...data, [e.target.name] : e.target.value})
     };

     const handleSubmit = (e) => {
          e.preventDefault();
          dispatch(forgotPassword(data));
          setData({ 'email': ''})
     }

     return (
          <main>
               <Container styles='theme__section flex flex-al-ctr flex-jus-ctr h-100'>
                    <form className='formCard cont-100 flex-col flex-al-ctr flex-jus-ctr formCard' onSubmit={ handleSubmit }>
                         <Text 
                              text='Please enter the email address you register your account with. 
                              We will send you reset password confirmation to this email'
                         />
                         <input 
                              type='email'
                              name='email'
                              value={ data.email }
                              onChange={ handleData }
                              placeholder='Email'
                              className='formCard__input'
                         />
                         <Response 
                              message={ message.text }
                              style={ message.style }
                         />
                         <input 
                              type='submit'
                              name='submit'
                              value='Send Reset'
                              className='basic-btn'
                              style={{backgroundColor: color }}
                         />
                         <Link to='/login'>Already have an account? Login</Link>
                    </form>
               </Container>
          </main>
     )
};

export default ForgotPassword;
