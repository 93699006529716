import React from 'react';

const BackgroundSlider = ({ img, styles, animate }) => {

     const slide = {
          backgroundImage: `url('${ img }')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
     };

     return (
          <div ref={ animate } className={ styles } style={ slide }></div>
     )
};

export default BackgroundSlider;
