import React, { useContext } from "react";
import { RiEditLine } from "react-icons/ri";
import { InvoiceContext } from "../../../context/context";

const CustomerCard = ({
	firstName,
	lastName,
	company,
	email,
	phone,
	address,
	city,
	country,
	postal,
}) => {
	const { customerFormStatus, setCustomerFormStatus } =
		useContext(InvoiceContext);

	return (
		<div className="customerCard">
			<p>{`${firstName} ${lastName}`}</p>
			<p>{company}</p>
			<p>{email}</p>
			<p>{phone}</p>
			<p>{`${address}`}</p>
			<p>{`${city}, ${country}, ${postal}`}</p>
			<RiEditLine
				className="dashboard__icon customerCard__edit-icon"
				onClick={() => setCustomerFormStatus(!customerFormStatus)}
			/>
		</div>
	);
};

export default CustomerCard;
