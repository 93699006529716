import React from "react";

const TextInput = ({ name, value, onchange, placeholder, onBlur }) => {
	return (
		<input
			type="text"
			name={name}
			value={value}
			onChange={onchange}
			placeholder={!placeholder ? name : placeholder}
			className="textInput"
			onBlur={onBlur}
		/>
	);
};

export default TextInput;
