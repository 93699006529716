import React, { useState, useEffect } from 'react';
import { Container, Response, Text } from '../components/index';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { resetPassword } from '../redux/authSlice';

function ResetPassword() {
     const color = 'rgb(234,224,200)';
     const dispatch = useDispatch();
     const { id } = useParams();
     const auth = useSelector(state => state.auth);
     const [ data, setData ] = useState({ password: '', confirmPassword: '', token: id});
     const [ message, setMessage ] = useState({ text: '', style: '' });

     useEffect(() => {
          if (auth) setMessage({ text: auth.message, style: auth.style }) 
          const timer = setTimeout(() => {
               setMessage({ text: '', style: '' });
          }, 5000);
          // CLEAR TIMEOUT
          return () => clearTimeout(timer);
     }, [auth]);

     const handleData = (e) => {
          e.preventDefault();
          setData({...data, [e.target.name] : e.target.value})
     };

     const handleSubmit = (e) => {
          e.preventDefault();
          console.log(data);
          dispatch(resetPassword(data));
          setData({ ...data, password: '', confirmPassword: ''})
     }

     return (
          <Container styles='theme__section flex flex-al-ctr flex-jus-ctr h-100'>
          <form className='formCard cont-100 flex-col flex-al-ctr flex-jus-ctr formCard' onSubmit={ handleSubmit }>
               <Text 
                    text='Please type new pasword'
               />
               <input 
                    type='password'
                    name='password'
                    value={ data.password }
                    onChange={ handleData }
                    placeholder='new password'
                    className='formCard__input'
               />
               <input 
                    type='password'
                    name='confirmPassword'
                    value={ data.confirmPassword }
                    onChange={ handleData }
                    placeholder='confirm password'
                    className='formCard__input'
               />
               <Response 
                    message={ message.text }
                    style={ message.style }
               />
               <input 
                    type='submit'
                    name='submit'
                    value='Send Reset'
                    className='basic-btn'
                    style={{backgroundColor: color }}
               />
               <Link to='/login'>Already have an account? Login</Link>
          </form>
     </Container>
     )
};

export default ResetPassword;
