import React, { useState, useEffect } from "react";
import { Container } from "../components/index";
import { Link } from "react-router-dom";
import { login } from "../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	const [data, setData] = useState({ email: "", password: "" });
	const [message, setMessage] = useState("");
	const color = "rgb(234,224,200)";

	useEffect(() => {
		if (auth) {
			setMessage(auth.error);
		}

		const timer = setTimeout(() => {
			setMessage("");
		}, 5000);
		// CLEAR TIMEOUT
		return () => clearTimeout(timer);
	}, [auth]);

	useEffect(() => {
		if (auth) {
			setMessage(auth.message);
		}

		const timer = setTimeout(() => {
			setMessage("");
		}, 5000);
		// CLEAR TIMEOUT
		return () => clearTimeout(timer);
	}, [auth]);

	const handleData = (e) => {
		e.preventDefault();
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(login(data)).then((res) => {
			setTimeout(() => {
				if (!res.error && res.payload.user) {
					navigate("/dashboard");
				}
			}, 1000);
		});
	};

	return (
		<main>
			<Container styles="theme__section flex flex-al-ctr flex-jus-ctr h-100">
				<form
					className="formCard cont-100 flex-col flex-al-ctr flex-jus-ctr formCard"
					onSubmit={handleSubmit}
				>
					<input
						type="email"
						name="email"
						value={data.email}
						onChange={handleData}
						placeholder="Email"
						className="formCard__input"
					/>
					<input
						type="password"
						name="password"
						value={data.password}
						onChange={handleData}
						placeholder="Password"
						className="formCard__input"
					/>
					<input
						type="submit"
						name="submit"
						value="Login"
						className="basic-btn"
						style={{ backgroundColor: color }}
					/>
					<p className={`response response-${auth.style}`}>
						{message}
					</p>
					<Link to="/register">
						Don't have an account. Sign up
					</Link>
					<Link to="/forgot-password">Forgot Password</Link>
				</form>
			</Container>
		</main>
	);
}

export default Login;
