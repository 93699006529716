import { configureStore } from '@reduxjs/toolkit';
import {authSlice} from './authSlice';
import {blogSlice} from './blogSlice';
import {portfolioSlice} from './portfolioSlice';

export const store = configureStore({
     reducer: {
          auth: authSlice.reducer,
          blog: blogSlice.reducer,
          portfolio: portfolioSlice.reducer
     },
     devTools: true
});
