import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Router from "./routes/index";
import { Header, Footer, ThemeBackground } from "./components/index";
import "./scss/style.scss";
import { useDispatch } from "react-redux";
import { getPosts } from "./redux/blogSlice";
import { isLogged } from "./redux/authSlice";
import { getPortfolio } from "./redux/portfolioSlice";
import { useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

export const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const App = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(isLogged());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getPosts());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getPortfolio());
	}, [dispatch]);

	return (
		<BrowserRouter>
			<ScrollToTop />
			<ThemeBackground />
			<Header />
			<Router />
			<Footer />
		</BrowserRouter>
	);
};

const root = createRoot(document.querySelector("#root"));

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</Provider>
	</React.StrictMode>
);
