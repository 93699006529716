import React from 'react';

const ChildBoardGrid = ({ children, styles }) => {
     // APPLY ONE OF THE AVAILABLE STYLES FROM GRID.SCSS
     
     return (
          <div className={ styles }>{ children }</div>
     )
};

export default ChildBoardGrid;