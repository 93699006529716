import React from 'react';

const SingleImage = ({styles, img}) => {

     const landscape = {
          backgroundImage: `url('${ img }')`,
          backgroundPosition: '25% center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
     };

     return (
          <div className='singleImage'>
               <div className={`singleImage__landscape ${ styles }`} style={ landscape }></div>
          </div>
     )
};

export default SingleImage;
