import React from 'react';
import { Section } from '../index';

const GridFlex = ({ children, styles }) => {
     return (
          <Section styles={`theme__section p-rel gridFlex ${ styles }`}>
               { children }
          </Section>
     )
};

export default GridFlex;

// Handles x-number of items
