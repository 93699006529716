import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get_Posts, add_Post, edit_Post, delete_Post } from "../api/api";

export const getPosts = createAsyncThunk("blog/getPosts", async (ThunkApi) => {
	try {
		const { data } = await get_Posts();
		return data;
	} catch (error) {
		return ThunkApi.rejectWithValue(
			error.response.data.error.split(",")[0]
		);
	}
});

export const addPost = createAsyncThunk(
	"blog/addPost",
	async (data, ThunkApi) => {
		try {
			const response = await add_Post(data);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const editPost = createAsyncThunk(
	"blog/editPost",
	async (data, ThunkApi) => {
		try {
			const response = await edit_Post(data);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const deletePost = createAsyncThunk(
	"blog/deletePost",
	async (id, ThunkApi) => {
		try {
			const response = await delete_Post(id);
			return response.data;
		} catch (error) {
			return ThunkApi.rejectWithValue(
				error.response.data.error.split(",")[0]
			);
		}
	}
);

export const blogSlice = createSlice({
	name: "blog",
	initialState: { posts: [], status: "", message: "", style: "" },
	reducers: {},
	extraReducers: (builder) => {
		// GET POSTS
		builder.addCase(getPosts.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		builder.addCase(getPosts.fulfilled, (state, { payload, meta }) => {
			state.posts = payload.data;
			state.status = meta.requestStatus;
			state.message = payload.message;
		});
		builder.addCase(getPosts.rejected, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		// ADD POST
		builder.addCase(addPost.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		builder.addCase(addPost.fulfilled, (state, { payload, meta }) => {
			state.posts = [...state.posts, payload.data];
			state.status = meta.requestStatus;
			state.message = payload.message;
			state.style = "message";
		});
		builder.addCase(addPost.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.message = payload;
			state.style = "error";
		});
		// EDIT POST
		builder.addCase(editPost.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		builder.addCase(editPost.fulfilled, (state, { payload, meta }) => {
			state.posts = state.posts.map((post) =>
				post._id === payload.data._id ? payload.data : post
			);
			state.status = meta.requestStatus;
			state.message = payload.message;
			state.style = "message";
		});
		builder.addCase(editPost.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.message = payload;
			state.style = "error";
		});
		// DELETE POST
		builder.addCase(deletePost.pending, (state, { meta }) => {
			state.status = meta.requestStatus;
		});
		builder.addCase(deletePost.fulfilled, (state, { payload, meta }) => {
			state.posts = state.posts.filter((i) => i._id !== payload.id);
			state.status = meta.requestStatus;
			state.message = payload.message;
			state.style = "message";
		});
		builder.addCase(deletePost.rejected, (state, { payload, meta }) => {
			state.status = meta.requestStatus;
			state.message = payload;
			state.style = "error";
		});
	},
});
