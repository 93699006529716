import React from 'react';
import { Container, Title, SubTitle, ButtonLink } from '../index';

const DblColumn = ({ title, subtitle, linkText, to, bgColor }) => {
     const background = {'backgroundColor' : `${bgColor}`}

     return (
          <Container styles='flex-col dblColumn'>
               <Container styles='flex-col flex-jus-ctr flex-al-st dblColumn__top' style={ background }>
                    <Title styles='dblColumn__title' title={ title }/>
                    <SubTitle styles='dblColumn__subtitle' title={ subtitle }/>
               </Container>
               <Container styles='flex flex-jus-ctr flex-al-ctr dblColumn__bottom'>
                    <ButtonLink text={ linkText } to={ to }/>
               </Container>
          </Container>
)};

export default DblColumn;
// Double Column 
// Top part could be anything 
// bottom is for buttom