import React from 'react';
import { Section, Container, Text, Title, Circle } from '../index';

const About = () => {
     return (
          <Section styles='theme__section--fullscreen mt-15 p-rel about'>
               <Container styles='cont-50 about__left'>
                    <div className='about__left--image'></div>
               </Container>
               <Container styles='cont-50 about__right'>
                    <div className='about__right--image'></div>
                    <Container styles='cont-100 about__right--content'>
                         <Title title='About'/>
                         <hr className='short--line'/>
                         <Text 
                              text='MiArtMedia | real estate and architectural photography was founded in 2017 
                              from a passion to create the best residential, interior and architectural photography 
                              services for the Calgary real estate market. Our aim is to improve the quality of 
                              photography available across the market and provide high end magazine ready photos 
                              for every property we visit. We want to show that this could all be done with a friendly 
                              and professional attitude so that every party involved enjoys the process.' 
                              styles='text about__right--text mr--tmd t-justify'
                         />
                    </Container>
               </Container>
               <Circle styles='about__right--circle' />
          </Section>
     )
};

export default About;
