import React from 'react';
import { CgDollar } from "react-icons/cg";

const Price = ({ price }) => {
     return (
          <p className='price'><CgDollar className='price__icon'/>{ price }</p>
     )
};

export default Price;
