import { useContext } from "react";
import { InvoiceContext } from "../../../context/context";
import { BsFillPersonPlusFill } from "react-icons/bs";

const AddCustomerButton = () => {
	const { customerFormStatus, setCustomerFormStatus } =
		useContext(InvoiceContext);

	const handle_customer_form_status = () => {
		setCustomerFormStatus(!customerFormStatus);
	};

	return (
		<div
			className="addCutomerButton flex flex-al-ctr flex-jus-ctr"
			onClick={handle_customer_form_status}
		>
			<BsFillPersonPlusFill className="addCutomerButton__icon" />
			<p>Add a customer</p>
		</div>
	);
};

export default AddCustomerButton;
