import React from 'react';
import { GridFlex, ListCard } from '../../../components/index';

const CommercialOption = () => {

     return (
          <React.Fragment>
               <GridFlex styles='pt-10'>
                    <ListCard 
                         title='What is included?'
                         list={
                              <React.Fragment>
                                   <li className='pt-1 pt-2'>Immediate Viewing – ability to see sample proofs as the shoot progresses.</li>
                                   <li className='pt-1 pt-2'>High-End Image Processing – up to 3 hours of professional editing per image, including multiple exposures editing, color cast correction, and standard image processing done in-house to your specifications.</li>
                                   <li className='pt-1 pt-2'>Licensing – non-exclusive licensing for each image to be used in marketing and advertising campaigns for an indefinite period of time. No 3rd party rights granted</li>
                                   <li className='pt-1 pt-2'>High-res Images – full set of high-resolution jpegs optimized for print and web display provided via digital download from Dropbox or Google Photos on your preference.</li>
                                   <li>Calculate your price</li>
                              </React.Fragment>
                         }
                         styles='flex-col flex-al-ctr flex-jus-ctr'
                         textCardStyles='cont-76 pt-2 pb-10'
                    />
               </GridFlex>
          </React.Fragment>
     )
};

export default CommercialOption;
