import React from 'react';
import { Container, Title, List } from '../index';

const ListCard = ({ title, list, textCardStyles, align, styles }) => {

     switch(align) {
          case 'center': align = 't-center';
               break;
          case 'justify': align = 't-justify';
               break;
          default: align = 't-center';
     }

     return (
          <Container styles={ textCardStyles }>
               <Title 
                    styles='title t-center'
                    title={ title }
               />
               <List 
                    styles={ styles }
                    children={ list }
               />
          </Container>
     )
};

export default ListCard;
