import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import {
	DblSectionMedia,
	DblColumn,
	SingleImage,
	BackgroundOverlay,
	ContactSection,
	GridFlex,
	BlogCard,
} from "../components/index";
import img from "../images/img-002.jpg";
import img2 from "../images/img-009.jpg";
import avatar from "../images/avatar.jpg";

const Blog = () => {
	const color = "rgb(114, 175, 206)";
	const posts = useSelector((state) => state.blog.posts);

	// EXTRACT SRC VALUE FROM BODY
	const handleSrc = (src) => {
		const regex = /src=["']([^"']+)/g;
		return src.match(regex)[0].substring(5);
	};

	const handleParagraph = (par) => {
		const regex = /<p.*?>(.*?)<\/p>/g;
		return par.match(regex)[0].replace(/(<([^>]+)>)/gi, "");
	};

	return (
		<main>
			<Helmet>
				<title>
					Real Estate Interior Architectural Food Photography
					Blog | MiArtMedia
				</title>
				<meta
					name="description"
					content="Why photography rent airbnb rooms faster? How photography benefits your restaurant? 
                    Check other tips on MiArtMedia Blog"
				/>
				<link
					rel="canonical"
					href="https://miartmedia.com/photography_blog"
				/>

				{/* Open Graph / Facebook */}
				<meta property="og:type" content="website" />
				<meta
					property="og:url"
					content="https://miartmedia.com/photography_blog"
				/>
				<meta
					property="og:title"
					content="Real Estate Interior Architectural Food Photography Blog | MiArtMedia"
				/>
				<meta
					property="og:description"
					content="Why photography rent airbnb rooms faster? How photography benefits your restaurant? 
                    Check other tips on MiArtMedia Blog"
				/>
				<meta property="og:image" content={img} />

				{/* Twitter */}
				<meta
					property="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content="https://miartmedia.com/photography_blog"
				/>
				<meta
					property="twitter:title"
					content="Real Estate Interior Architectural Food Photography Blog | MiArtMedia"
				/>
				<meta
					property="twitter:description"
					content="Why photography rent airbnb rooms faster? How photography benefits your restaurant? 
                    Check other tips on MiArtMedia Blog"
				/>
				<meta property="twitter:image" content={img}></meta>
			</Helmet>
			<DblSectionMedia
				textSide={
					<DblColumn
						title="Photgraphy Blog"
						subtitle="MiArtMedia Creative Studio"
						linkText="Get in Touch"
						to="/contact"
						bgColor={color}
					/>
				}
				imageSide={
					<React.Fragment>
						<SingleImage
							styles="dblSection--image"
							img={img}
						/>
						<BackgroundOverlay styles="dblSection--backgroundOverlay" />
					</React.Fragment>
				}
			/>
			<GridFlex styles="theme__section p-rel flex flex-al-ctr flex-jus-ctr pt-10 pb-10">
				{posts
					.map((i) => (
						<BlogCard
							key={i._id}
							mainImg={handleSrc(i.body)}
							logo={avatar}
							author={
								i.user ? i.user : "Mikhail Zapolskyi"
							}
							title={i.title}
							dateDay={moment(i.createdAt).format("D")}
							dateMonYear={moment(i.createdAt).format(
								"MMMM YYYY"
							)}
							text={`${handleParagraph(i.body).substring(
								0,
								300
							)}..`}
							link={i.title
								.replace(/ /g, "_")
								.toLowerCase()}
						/>
					))
					.reverse()}
			</GridFlex>
			<ContactSection
				img={img2}
				title="REGARDING PHOTOGRAPHY SERVICES:"
				text={
					<React.Fragment>
						To contact MiArtMedia Creative Studio for
						photography services or other matters, you may
						reach us through our contact page or email us at
						info@miartmedia.com
					</React.Fragment>
				}
				button={
					<Link
						to="/contact"
						className="basic-btn"
						style={{ backgroundColor: color }}
					>
						Get in Touch
					</Link>
				}
			/>
		</main>
	);
};

export default Blog;
