import React, { useState } from 'react';
import { TiArrowRightThick, TiArrowLeftThick, TiDeleteOutline } from "react-icons/ti";
import { useSelector } from 'react-redux';


const Lightbox = ({ showImage, closeLightbox, ID }) => {
     const [ project ] = useSelector(state => state.portfolio.projects).filter(item => item._id === ID);
     const images = project.images.map(item => item.image);

     const getIndex = (img) => {
          const regex = img.replace(/\s/g, ' ');
          const imgIndex = !images ? false : images.indexOf(regex);
          return imgIndex;
     };     
     const [ imageIndex, setImageIndex ] = useState(getIndex(showImage));

     const handleChange = (e) => {
         e.preventDefault();
         setImageIndex(getIndex(e.target.src));
     };

     const handleChangeByArrowNext = () => {
          setImageIndex((imageIndex + 1) % images.length);
     }

     const handleChangeByArrowPrev = () => {
          imageIndex <= 0 ? setImageIndex(images.length - 1) : setImageIndex(imageIndex - 1);
     }

     return (
          <div className='lightbox'>
               <div className='lightbox__main'>
                    <img src={ images[imageIndex] } className='lightbox__main-image' alt=""/>
               </div>
               <div className='lightbox__thumbnail'>
                    {images.filter((img, index) => index > imageIndex - 2 && index < imageIndex + 2 ).map((img, key) => 
                         <div className='lightbox__thumbnail-cont' key={ key }>
                              <img src={ img } className='lightbox__thumbnail-image' alt={ img } onClick={ handleChange }/>
                         </div>
                    )}
               </div>
               <TiArrowRightThick className='lightbox__arrow-right' id='next' onClick={ handleChangeByArrowNext }/>
               <TiArrowLeftThick className='lightbox__arrow-left' id='prev' onClick={ handleChangeByArrowPrev }/>
               <TiDeleteOutline className='lightbox__close' onClick={ closeLightbox }/>
          </div>
     );
}

export default Lightbox;