import React from 'react';
import { RiAddCircleFill } from 'react-icons/ri';

const AddButton = ({ func }) => {
     return (
          <div className='flex flex-al-ctr flex-jus-ctr'>
               <RiAddCircleFill className='addButton' onClick={ func }/>
          </div>
     )
};

export default AddButton;