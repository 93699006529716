import React from 'react';

function Appointment() {
     return (
          <div>
               Appointment
          </div>
     )
};

export default Appointment;
