import React, { useState, useEffect } from "react";
import { Container, Response } from "../components/index";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../redux/authSlice";

const Register = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	const [data, setData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		secret: "",
	});
	const [message, setMessage] = useState("");
	const color = "rgb(234,224,200)";

	useEffect(() => {
		if (auth) {
			setMessage(auth.error);
		}
		setTimeout(() => {
			setMessage("");
		}, 5000);
	}, [auth]);

	useEffect(() => {
		if (auth) {
			setMessage(auth.message);
		}
		setTimeout(() => {
			setMessage("");
		}, 5000);
	}, [auth]);

	const handleData = (e) => {
		e.preventDefault();
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(register(data)).then((res) => {
			setTimeout(() => {
				if (!res.error && res.payload.user) navigate("/dashboard");
			}, 1000);
		});
	};

	return (
		<main>
			<Container styles="theme__section flex flex-al-ctr flex-jus-ctr h-100">
				<form
					className="formCard cont-100 flex-col flex-al-ctr flex-jus-ctr formCard"
					onSubmit={handleSubmit}
				>
					<input
						type="email"
						name="email"
						value={data.email}
						onChange={handleData}
						placeholder="Email"
						className="formCard__input"
					/>
					<input
						type="password"
						name="password"
						value={data.password}
						onChange={handleData}
						placeholder="Password"
						className="formCard__input"
					/>
					<input
						type="password"
						name="confirmPassword"
						value={data.confirmPassword}
						onChange={handleData}
						placeholder="Confirm Password"
						className="formCard__input"
					/>
					<input
						type="text"
						name="secret"
						value={data.secret}
						onChange={handleData}
						placeholder="Enter the code provided by admin"
						className="formCard__input"
					/>
					<input
						type="submit"
						name="submit"
						value="Register"
						className="basic-btn"
						style={{ backgroundColor: color }}
					/>
					<Response style={auth.style} message={message} />
					<Link to="/login">Already have an account? Login</Link>
					<Link to="/forgot-password">Forgot Password</Link>
				</form>
			</Container>
		</main>
	);
};

export default Register;
