import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	BasicBoard,
	ChildBoardGrid,
	AddButton,
	EditButton,
	DeleteButton,
	CommonTable,
	TextInput,
} from "../../../components/index";
import { AddProject, EditProject } from "../../index";
import { delProject } from "../../../redux/portfolioSlice";
import moment from "moment";

const PortfolioBoard = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.portfolio.projects);
	const [addForm, setAddForm] = useState(false);
	const [editForm, setEditForm] = useState(false);
	const [projectId, setProjectId] = useState("");
	const [images, setImages] = useState([]);

	const checkForm = (state) => {
		setTimeout(() => {
			setAddForm(state);
			setEditForm(state);
		}, 1000);
	};

	const handleSearchCategory = (e) => {
		e.preventDefault();
		setImages(
			data.filter(
				(i) =>
					i.title
						.toLowerCase()
						.includes(e.target.value.toLowerCase()) ||
					i.category
						.toLowerCase()
						.includes(e.target.value.toLowerCase())
			)
		);
	};

	const handleProjectEdit = (id) => {
		setProjectId(id);
		setEditForm(!editForm);
	};

	return (
		<BasicBoard>
			<ChildBoardGrid styles="childBoardGridRow-1">
				<div className="grid-col-90-10">
					<TextInput
						name="search"
						onchange={handleSearchCategory}
					/>
					<AddButton func={() => setAddForm(!addForm)} />
				</div>
				<CommonTable>
					{(images.length === 0 ? data : images)
						.map((i) => (
							<ul
								className="commonTable__tbody"
								key={i._id}
							>
								<li>
									<img
										src={i.images[0].image}
										alt={i.images[0]}
									/>
								</li>
								<li>{i.title.split("-")[0]}</li>
								<li>{i.category}</li>
								<li>{moment(i.createdAt).fromNow()}</li>
								<li className="flex flex-jus-even">
									<EditButton
										func={() =>
											handleProjectEdit(i._id)
										}
									/>
									<DeleteButton
										func={() =>
											dispatch(
												delProject(i._id)
											)
										}
									/>
								</li>
							</ul>
						))
						.reverse()}
				</CommonTable>
			</ChildBoardGrid>
			<AddProject
				active={addForm}
				onclick={() => setAddForm(!addForm)}
				sendFormState={checkForm}
			/>
			<EditProject
				active={editForm}
				onclick={() => setEditForm(!editForm)}
				sendFormState={checkForm}
				projectId={projectId}
			/>
		</BasicBoard>
	);
};

export default PortfolioBoard;
