import React from "react";
import { useLocation } from 'react-router-dom';

const ThemeBackgound = () => {
     const location = useLocation();
     const [ path ] = ['/dashboard', '/dashboard/blog', '/dashboard/portfolio', '/dashboard/notifications', '/dashboard/billing', '/dashboard/profile',
     '/dashboard/rank'].filter(item => location.pathname === item);
     let lines = [];

     for ( let i=0; i < 7; i++ ){
          lines.push(<div key={`ThemeBackgound ${i}`} className="themeBackground__line"></div>)
     }

     return (
          <React.Fragment>
               { location.pathname === path ? false : 
                    <div className="themeBackground">
                         <div className="themeBackground__wrapper">
                              { lines.map(line => line) }
                         </div>
                    </div>
               }
          </React.Fragment>
     )
};

export default ThemeBackgound;